import React, {FunctionComponent} from "react";
import Title from "../components/text/title";
import CitySearchBar from "../components/citySearchBar";
import "./weather.css";

const Weather: FunctionComponent = () => {
  return (
    <main className="weather_main">
      <div className="title_container">
        <Title title="Prévisions"/>
        <h3 className="title_container__subtitle">Tous les indicateurs météo de
          votre ville</h3>
      </div>
      <div className="searchBar_container">
        <CitySearchBar url="/weather/results"/>
      </div>
    </main>
  );
};

export default Weather;
