import React, { FunctionComponent } from "react";
import "./widget.css";
import "./digitalHour.css"
import { weatherData } from "../../types/weather";

type Props = {
  data?: weatherData;
  city?: string;
};

const DigitalHourWidget: FunctionComponent<Props> = (props: Props) => {

  function getFormattedHour(): string {
    if (!props.data)
      return "00:00";
    const now = new Date();

    const daySecs = (now.getUTCHours() * 3600 + now.getUTCMinutes() * 60 + props.data.actual.utcDelta) % (24 * 3600);
    const hours = Math.floor(daySecs / 3600);
    const minutes = Math.floor((daySecs % 3600) / 60);

    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  }

  function getFormattedTimeZone(): string {
    if (!props.data)
      return "UTC";
    
    const hour = Math.floor(props.data.actual.utcDelta / 3600);
    const minutes = Math.floor((props.data.actual.utcDelta % 3600) / 60);
    return `UTC ${props.data.actual.utcDelta > 0 ? '+' : ''}${hour}:${minutes < 10 ? "0" + minutes : minutes}`;
  }


  return (
    <>
      <span className="widget-title">{props.city}</span>
      <span className="widget-digtal-hour__data">{getFormattedHour()}</span>
      <span className="widget-digtal-hour__timezone">{getFormattedTimeZone()}</span>
    </>
  )
}

export default DigitalHourWidget;
