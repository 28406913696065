import React, {FunctionComponent} from "react";
import "./barometer.css"
import barometer from "../../../svg/barometer.svg"
import barometerArrow from "../../../svg/barometer_cursor.svg"
import Icon from "../../icons/weatherIcon";
import {weatherData} from "../../../types/weather";


type Props = {
  data?: weatherData;
};

const BarometerComponent: FunctionComponent<Props> = (props: Props) => {

  const pressureEquivalence = [
    {pressure: [960, 975], weather: 6},
    {pressure: [975, 990], weather: 5},
    {pressure: [990, 1010], weather: 3},
    {pressure: [1010, 1025], weather: 1},
    {pressure: [1025, 1060], weather: 0},
  ]

  function getBarometerArrowRotation(): number {
    if (!props.data)
      return 0;
    if (props.data.actual.weather.pressure < 960)
      return -145
    if (props.data.actual.weather.pressure > 1060)
      return 145
    const rotation = (props.data.actual.weather.pressure - 960) / 100 * 290;
    return rotation - 145;
  }

  function getBarometerIcon(): number {
    if (!props.data)
      return 0;

    for (let i = 0; i < pressureEquivalence.length; i++) {
      if (pressureEquivalence[i].pressure[0] <= props.data.actual.weather.pressure && pressureEquivalence[i].pressure[1] >= props.data.actual.weather.pressure) {
        // console.log("here");
        return pressureEquivalence[i].weather;
      }
    }
    return 0;
  }

  return (
    <>
      <img src={barometer} alt="barometer" className="barometer__img"/>
      <img src={barometerArrow} alt="Barometer arrow"
           className="barometer__arrow"
           style={{transform: `translateX(-50%) rotate(${getBarometerArrowRotation()}deg)`}}/>
      <div className="barometer__infos">
        <span
          className="barometer__infos--data">{props.data?.actual.weather.pressure}</span>
        <span className="barometer__infos--unit">hPa</span>
      </div>
      <Icon weatherCode={getBarometerIcon()} color={1} class="barometer__icon"/>
    </>
  )
}

export default BarometerComponent;
