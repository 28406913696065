import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from "react";
import LoupeButton from "../buttons/loupeButton";
import PrimaryButton from "../buttons/primaryButton";
import "../citySearchBar.css";

type Props = {
  addBoard: (cityName: string, long: string, lat: string) => void
};

type ButtonProps = {
  action?: React.MouseEventHandler<HTMLAnchorElement>;
};

type ResultsProps = {
  cities: Array<CityResult>;
  addBoard: (cityName: string, long: string, lat: string) => void;
  resetForm: () => void;
};

type CityResult = {
  formatted: string;
  city: string;
  country: string;
  countryCode: string;
  postcode: string;
  key: number
  latitude: number;
  longitude: number;
}

const SearchButton: FunctionComponent<ButtonProps> = (props) => {

  const [width, setWidth] = useState<number>(window.innerWidth);

  const windowSizeHandler = (ev: UIEvent) => {
    setWidth(window.innerWidth);
  };

  window.addEventListener("resize", windowSizeHandler);

  return <>{width < 950 ? <LoupeButton action={props.action}/> :
    <PrimaryButton action={props.action} content="Rechercher"
                   style={{
                     margin: ".25rem 0",
                     padding: ".5rem 1rem ",
                     height: "2.25rem"
                   }}/>}</>;
}

const SearchResults: FunctionComponent<ResultsProps> = ({
                                                          cities,
                                                          addBoard,
                                                          resetForm
                                                        }) => {

  return (
    <ul className="search-results">
      {cities.map(city => (
        <a className="search-results__li"
           key={city.key} onClick={(ev) => {
          ev.preventDefault();
          addBoard(city.city, city.longitude.toString(), city.latitude.toString())
          resetForm();
        }}>
          <li>{city.formatted}</li>
        </a>
      ))}
    </ul>
  );
}

const CitySearchBar: FunctionComponent<Props> = ({addBoard}) => {

  const [cityResults, setCityResults] = useState<Array<CityResult>>([]);
  const [inputValue, setInputValue] = useState<string>("");

  async function getCityInfos(cityName: string) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/geocoding/get-city?cityName=${cityName}`, {
      method: 'GET',
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      mode: "cors",
    });
    let searchResult: Array<CityResult> = [];
    setCityResults([]);
    if (response.status !== 200) {
      return 0;
    }
    let cityList = (await (response.json())).results;
    if (!cityList || !cityList.length)
      return 0;
    for (let i = 0; i < 5 && i < cityList.length; i++) {
      let postcode = '';
      if (cityList[i].postcodes && cityList[i].postcodes.length > 0) {
        postcode = cityList[i].postcodes[0];
      }
      searchResult.push({
        formatted: cityList[i].name + " - " + postcode + " (" + cityList[i].country_code + ")",
        city: cityList[i].name,
        country: cityList[i].country,
        countryCode: cityList[i].country_code,
        postcode: postcode,
        key: i,
        latitude: cityList[i].latitude,
        longitude: cityList[i].longitude
      })
    }
    setCityResults(searchResult);
  }

  const handleChange = async (ev: FormEvent<HTMLInputElement>) => {
    ev.preventDefault();
    setInputValue(ev.currentTarget.value);
    if (inputValue.length < 3)
      return;
    await getCityInfos(inputValue);
  }

  useEffect(() => {
    if (inputValue.length < 2 && cityResults.length !== 0)
      setCityResults([]);
  }, [cityResults, inputValue]);

  const resetForm = () => {
    setInputValue("");
    setCityResults([]);
  }

  function handleEnterEvent(event: FormEvent): void {
    event.preventDefault();
    if (cityResults.length === 0 || cityResults[0] === undefined) {
      return;
    }
    addBoard(cityResults[0].city, cityResults[0].longitude.toString(), cityResults[0].latitude.toString());
    resetForm();
    return;
  }

  return (
    <form className="city-searchbar" onSubmit={handleEnterEvent}>
      <div className="city-searchbar__main">
        <div className="city-searchbar__main--input">
          <input type='text'
                 className="city-searchbar__main--input__text"
                 placeholder="Rechercher une ville..."
                 value={inputValue}
                 onChange={handleChange}></input>
        </div>
        {cityResults.length > 0 ?
          <SearchResults addBoard={addBoard} cities={cityResults}
                         resetForm={resetForm}/> : null}
      </div>
      <SearchButton action={(ev) => {
        ev.preventDefault();
        addBoard(cityResults[0].city, cityResults[0].longitude.toString(), cityResults[0].latitude.toString());
        resetForm();
      }}/>
    </form>
  )
}

export default CitySearchBar;
