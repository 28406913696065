import React, {
  ChangeEvent, ChangeEventHandler, FormEventHandler,
  FunctionComponent,
  useEffect,
  useState
} from "react";
import "./account.css";
import Title from "../components/text/title";
import {useDispatch, useSelector} from "react-redux";
import {getUserConnected} from "../redux/userSelector";
import {Link, Navigate, useNavigate} from "react-router-dom";
import editButton from "../icons/editButton.svg";
import validate from "../icons/valid-icon.svg";
import invalidate from "../icons/invalid_icon.svg";
import {setUser} from "../redux/actions/userActions";
import UserService from "../services/user.service";
import {User} from "../types/userType";
import RedButton from "../components/buttons/redButton";
import closeIcon from "../icons/delete.svg";
import success from "../icons/valid-icon.svg";
import error from "../icons/invalid_icon.svg";
import ProtectedRoute from "../helpers/isUserConnected";

type FormFieldProps = {
  value: string
  update: (value: string) => void
  name: string
}

type DeleteModal = {
  user: User
  hide: () => void
  token: string
}

const FormField: FunctionComponent<FormFieldProps> = ({
                                                        value,
                                                        update,
                                                        name
                                                      }) => {
  const [editable, setEditable] = useState<boolean>(false)
  const [save, setSave] = useState<string>(value);
  const [fieldValue, setFieldvalue] = useState<string>(value);

  useEffect(() => {
    if (editable)
      setSave(fieldValue);
  }, [editable]);

  return (
    <div className="field__container">
      <label htmlFor={name}>{name}</label>
      <div className="input__container">
        <input className="input__filed-account" id={name} type="text"
               value={fieldValue} readOnly={!editable} onChange={(e) => {
          setFieldvalue(e.currentTarget.value)
        }}/>
        <div>
          {!editable ?
            <img src={editButton} alt={`edit account ${name}`}
                 onClick={() => setEditable(true)}/> :
            <div className="modification__container">
              <img src={validate} alt="validate modification" onClick={() => {
                update(fieldValue);
                setEditable(false);
              }}/>
              <img src={invalidate} alt="invalid modification" onClick={() => {
                setFieldvalue(save)
                setEditable(false)
              }}/>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const DeleteAccountModale: FunctionComponent<DeleteModal> = ({
                                                               user,
                                                               hide,
                                                               token
                                                             }) => {
  const [text, setText] = useState<string>("");
  const [waiting, setWaiting] = useState<boolean>(false);
  const userService = new UserService();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [showConf, setShowConf] = useState<boolean>(false);
  const [confStatus, setConfStatus] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    setText(event.currentTarget.value);
  }

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    if (!canSubmit)
      return;
    setWaiting(true);
    const result = await userService.deleteUser(token, user);
    setWaiting(false);
    setShowConf(true);
    setConfStatus(result);
  }

  useEffect(() => {
    setCanSubmit(text === user.email)
  }, [text]);

  const handleRedirection = () => {
    if (!confStatus) {
      setShowConf(false);
      return;
    }
    dispatch(setUser(null));
    localStorage.clear();
    setShowConf(false);
    navigate('/');
  }

  if (showConf) {
    return (
      <div className="delete__modal">
        <div className="delete__modal-container center">
          <img className="confirmation__icon" src={confStatus ? success : error}
               alt="icon"/>
          <p className="confirmation__text">
            {confStatus ? "Votre compte a été supprimé avec succès." :
              "Erreur lors de la suppression du compte, merci de réessayer plus tard."}
          </p>
          <button className={confStatus ? "green" : "red"}
                  onClick={() => handleRedirection()}>
            {confStatus ? "Retour au menu" : "Réessayer"}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="delete__modal">
      {waiting ?
        <div className="delete__modal-container loader">
          <svg width="46" height="46" viewBox="0 0 46 46" fill="none"
               xmlns="http://www.w3.org/2000/svg"
               className={"widget-loader"}>
            <path className="line1"
                  d="M34.2858 9.92473C34.2858 6.65786 31.4839 4 28.0399 4H26.9631V7.06452H28.0399C29.7025 7.06452 31.0552 8.34763 31.0552 9.92473C31.0552 11.5018 29.7025 12.7849 28.0399 12.7849H0.148926V15.8495H28.0399C31.4839 15.8495 34.2858 13.1917 34.2858 9.92473Z"
                  fill="#1F7A8C"/>
            <path className="line2"
                  d="M36.1165 30.1504H0.148926V33.2149H36.1165C37.7791 33.2149 39.1317 34.498 39.1317 36.0751C39.1317 37.6522 37.7791 38.9353 36.1165 38.9353H35.0396V41.9999H36.1165C39.5604 41.9999 42.3624 39.342 42.3624 36.0751C42.3624 32.8082 39.5604 30.1504 36.1165 30.1504Z"
                  fill="#1F7A8C"/>
            <path className="line3"
                  d="M49.1467 21.4678H14.7944V24.5323H31.9706H49.1467V21.4678Z"
                  fill="#1F7A8C"/>
            <path className="line4"
                  d="M11.5638 21.4678H0V24.5323H11.5638V21.4678Z"
                  fill="#1F7A8C"/>
          </svg>
        </div>
        :
        <div className="delete__modal-container">
          <div className="delete__modal-close">
            <img src={closeIcon} alt="close modal" onClick={() => hide()}/>
          </div>
          <p className="delete__modal-title">Êtes vous sûrs de vouloir supprimer
            votre compte ?</p>
          <p className="delete__modal-subtitle">Toutes vos données seront
            <span> effacées</span> de manière <span>irréversible</span>.</p>
          <form className="delete__form" onSubmit={handleSubmit}>
            <label htmlFor="deleteConf">Pour confirmer, merci de recopier le
              texte suivant : <span>{user.email}</span></label>
            <input id="deleteConf" type="text" onChange={changeHandler}
                   value={text}/>
            <button className={!canSubmit ? "inactive" : ""}>Supprimer mon
              compte
            </button>
          </form>
        </div>
      }
    </div>
  )
}

const Account: FunctionComponent = () => {
  const userConnected = useSelector(getUserConnected);
  const userService = new UserService();
  const token = localStorage.getItem("token")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState<boolean>(false);


  useEffect(() => {
    console.log("account mounted")
    const div: HTMLDivElement | null = document.querySelector('#root');
    if (!div)
      return;
    div.style.height = "100%";
    return () => {
      div.style.height = "auto";
    }
  }, []);

  if (!userConnected || !token) {
    console.log("redirect by component himself");
    return (
      <Navigate to={'/'} replace/>
    )
  }

  return (
    <>
      <main className="main__account">
        {showDelete && <DeleteAccountModale user={userConnected}
                                            hide={() => setShowDelete(false)}
                                            token={token}/>}
        <div className="background__container"></div>
        <section className="account__section">
          <Title title={`Bonjour ${userConnected.name}`}/>
          <div className="account__container">
            <div className="account__form">
              <FormField key={`fieldMail`} value={userConnected.email}
                         update={(value: string) => {
                           const updated: User = {...userConnected, ...{email: value}}
                           userService.updateUser(token, updated);
                         }} name="Email"/>
              <FormField key={`fieldFirstname`} value={userConnected.name}
                         update={(value: string) => {
                           const updated: User = {...userConnected, ...{name: value}}
                           userService.updateUser(token, updated);
                         }} name="Prénom"/>
              <FormField key={`fieldName`} value={userConnected.lastname}
                         update={(value: string) => {
                           const updated: User = {...userConnected, ...{lastname: value}}
                           userService.updateUser(token, updated);
                         }} name="Nom"/>
              <FormField key={`fieldCity`} value={userConnected.residenceCity}
                         update={(value: string) => {
                           const updated: User = {...userConnected, ...{residenceCity: value}}
                           userService.updateUser(token, updated);
                         }} name="Ville de résidence"/>
            </div>
            <div className="link__container">
              <Link className="link link-green" to="/reset-password">Changer mon
                mot de
                passe</Link>
              <button className="link link-red"
                      onClick={() => setShowDelete(true)}>Supprimer mon
                compte
              </button>
            </div>
            <RedButton content="Déconnexion" action={() => {
              console.log("disconnect")
              dispatch(setUser(null));
              localStorage.clear();
              navigate('/');
            }}/>
          </div>
        </section>
      </main>
    </>
  )
};

export default Account;
