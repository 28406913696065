import React, { FunctionComponent, ReactNode } from "react";
import "./widget.css";
import "./tempGraph.css"
import { weatherData } from "../../types/weather";
import Icon from "../icons/weatherIcon";


type Props = {
  data?: weatherData;
};

const TempGrapWidget: FunctionComponent<Props> = (props: Props) => {

  if (!props.data) {
    return <></>
  }

  const columns = [];
  const maxTemp = Math.max(...props.data?.next24.map((hour) => hour.temp));
  const minTemp = Math.min(...props.data?.next24.map((hour) => hour.temp));

  for (let i = 0; i < 8; i++) {
    columns.push(getGraphColumns(i));
  }

  function getCandleSize(index: number): React.CSSProperties {
    if (!props.data)
      return { height: `0px` };
    const height = (100 * (props.data?.next24[index].temp - minTemp) / (maxTemp - minTemp)) * 0.5;
    return { height: `${height + 20}px`, marginTop: `${70 - height - 20}px` };
  }

  function getGraphColumns(index: number): ReactNode {
    return (
      <div className="widget-tempgraph__left--colmun" key={index}>
        <span className="widget-tempgraph__left--colmun__data">{props.data?.next24[index].temp.toFixed(0)}°</span>
        <div className="widget-tempgraph__left--colmun__candle" style={getCandleSize(index)}></div>
        <span className="widget-tempgraph__left--colmun__data">{props.data?.next24[index].hour}</span>
        <Icon weatherCode={props.data?.next24[index].weatherType} color={1} class="widget-tempgraph__left--colmun__icon" />
      </div>
    )
  }

  function getRainSum(data: weatherData["next24"]) {
    let sum = 0;
    data.forEach((hour) => {
      sum += hour.rainVolume;
    })
    return sum.toFixed(1);
  }

  if (!props.data) {
    return null;
  }

  return (
    <>
      <div className="widget-tempgraph">
        <div className="widget-tempgraph__left">
          {columns}
        </div>
        <div className="widget-tempgraph__separator"></div>
        <div className="widget-tempgraph__right">
          <span className="widget-tempgraph__right--title">Volume dans les 24h:</span>
          <div>
            <span className="widget-tempgraph__right--data">{getRainSum(props.data.next24)}</span><span className="widget-tempgraph__right--unit">mm</span>
          </div>
        </div>

      </div>
    </>
  )
}

export default TempGrapWidget;
