import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useState
} from "react";
import ReactGridLayout, {
  Layouts,
  Responsive,
  WidthProvider,
  Layout
} from "react-grid-layout";
import "./editableGrid.css";
import Widget from "../widgets/widget";
import ModalWidget from "./modaleWidget";
import { weatherData } from "../../types/weather";
import { boardInfos } from "../../pages/board";
import editButton from "../../icons/editButton.svg";
import validButton from "../../icons/valid-icon.svg";
import RedButton from "../buttons/redButton";

type gridProps = {
  boardInfos: boardInfos;
  data?: weatherData;
  boardId: number;
  style?: CSSProperties
  deleteBoard: (id: number) => void
}

export type widgetDim = { w: number, h: number, area: number };

const finder = (el: Layout, x: number, y: number): boolean => {
  return (el.x === x && el.y === y)
}

const getLayoutForAmountOfColumns = (col: number, layout: Layout[]): Layout[] => {
  let result: Layout[] = [];
  if (col % 2 !== 0)
    return [];
  layout.forEach((widget) => {
    let tmp = Object.assign({}, widget);
    tmp.y = Math.floor(((6 * tmp.y) + tmp.x) / col);
    tmp.x = ((6 * tmp.y) + tmp.x) % col;
    result.push(tmp);
  })
  return result;
}

const getEditLayout = (layout: Layout[]): Layouts => {
  const tmp: Layout[] = [
    { i: "", x: 0, y: 0, w: 1, h: 1 },
    { i: "", x: 1, y: 0, w: 1, h: 1 },
    { i: "", x: 2, y: 0, w: 1, h: 1 },
    { i: "", x: 3, y: 0, w: 1, h: 1 },
    { i: "", x: 4, y: 0, w: 1, h: 1 },
    { i: "", x: 5, y: 0, w: 1, h: 1 },
    { i: "", x: 0, y: 1, w: 1, h: 1 },
    { i: "", x: 1, y: 1, w: 1, h: 1 },
    { i: "", x: 2, y: 1, w: 1, h: 1 },
    { i: "", x: 3, y: 1, w: 1, h: 1 },
    { i: "", x: 4, y: 1, w: 1, h: 1 },
    { i: "", x: 5, y: 1, w: 1, h: 1 },
    { i: "", x: 0, y: 2, w: 1, h: 1 },
    { i: "", x: 1, y: 2, w: 1, h: 1 },
    { i: "", x: 2, y: 2, w: 1, h: 1 },
    { i: "", x: 3, y: 2, w: 1, h: 1 },
    { i: "", x: 4, y: 2, w: 1, h: 1 },
    { i: "", x: 5, y: 2, w: 1, h: 1 },
    { i: "", x: 0, y: 3, w: 1, h: 1 },
    { i: "", x: 1, y: 3, w: 1, h: 1 },
    { i: "", x: 2, y: 3, w: 1, h: 1 },
    { i: "", x: 3, y: 3, w: 1, h: 1 },
    { i: "", x: 4, y: 3, w: 1, h: 1 },
    { i: "", x: 5, y: 3, w: 1, h: 1 },
  ];
  layout.forEach(widget => {
    let index = -1;
    for (let i = widget.y; i < (widget.y + widget.h); i++) {
      index = tmp.findIndex((el) => finder(el, widget.x, i));
      if (index < 0)
        return;
      if (i === widget.y) {
        tmp.splice(index, widget.w, widget);
        continue;
      }
      tmp.splice(index, widget.w);
    }
  })
  let index = 0
  tmp.map((widget) => {
    if (widget.i === "") {
      widget.i = `lg${index++}`;
    }
    return widget;
  })
  return {
    lg: tmp,
    md: getLayoutForAmountOfColumns(4, tmp),
    xs: getLayoutForAmountOfColumns(2, tmp)
  };
}


const getStaticLayout = (layout: Layout[]): Layout[] => {
  return layout.filter(el => (!el.i.includes("lg")));
}

const EditableGrid: FunctionComponent<gridProps> = ({
  boardInfos,
  data,
  boardId,
  style,
  deleteBoard
}) => {
  const ResponsiveGridLayout = WidthProvider(Responsive);
  const [tmpLayout, setTmpLayout] = useState<Layouts>({ lg: boardInfos.layout });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newWidgetLayout, setNewWidgetLayout] = useState<Layout | null>(null);
  const [editable, setEditable] = useState<boolean>(false);
  const [maxDim, setMaxDim] = useState<widgetDim>({ w: 0, h: 0, area: 0 });
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);
  const token = localStorage.getItem("token");


  const createElement = (layout: Layout) => {
    return (
      <div className="results-container__widgets"
        key={layout.i}>
        <Widget editable={editable} type={layout.i}
          showModal={() => {
            setShowModal(true);
            setNewWidgetLayout(layout);
            calcMaxArea(layout.x, layout.y)
          }}
          deleteWidget={removeWidget} data={data} />
      </div>
    )
  }

  async function updateBoard() {
    boardInfos.layout = getStaticLayout(tmpLayout.lg)
    const toSend = { id: boardId, infos: JSON.stringify(boardInfos) };
    const response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/board/update-board`, {
      method: 'PUT',
      headers: {
        "content-type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${token}`,
      },
      mode: "cors",
      body: JSON.stringify(toSend)
    });
    if (!response.ok)
      console.log("error while updating")
  }

  const calcMaxArea = (x: number, y: number) => {
    let w: number = 1;
    let h: number = 1;
    let area: number = 0;
    console.log(x, y);
    const tmp = getStaticLayout(tmpLayout.lg);
    if (x + 1 < 6 && tmp.find(el => el.x === x + 1 && el.y === y) === undefined) {
      w++;
    }
    if (y + 1 < 4 && tmp.find(el => el.x === x && el.y === y + 1) === undefined) {
      h++;
    }
    area = w * h;
    if (tmp.find(el => el.x === x + 1 && el.y === y + 1) !== undefined)
      area--;
    setMaxDim({ w: w, h: h, area: area });
  }

  const removeWidget = (key: string | undefined) => {
    if (!key)
      return;
    const index = tmpLayout.lg.findIndex((el) => el.i === key);
    if (index === -1)
      return;
    let tmp = tmpLayout.lg;
    tmp.splice(index, 1);
    setTmpLayout(getEditLayout(getStaticLayout(tmp)));
    setNeedUpdate(true);
  }

  useEffect(() => {
    setTmpLayout(getEditLayout(boardInfos.layout));
  }, []);

  const addToLayout = (key: string, w: number, h: number) => {
    if (!newWidgetLayout || key === "")
      return;
    let index = tmpLayout.lg.findIndex((obj) => obj.i === newWidgetLayout.i);
    if (index === -1)
      return;
    let tmp = tmpLayout;
    tmp.lg[index].i = key;
    tmp.lg[index].w = w;
    tmp.lg[index].h = h;
    setTmpLayout(getEditLayout(getStaticLayout(tmp.lg)));
    setNeedUpdate(true);
  }

  const hideModal = () => {
    setShowModal(false);
    setNewWidgetLayout(null);
    setMaxDim({ w: 0, h: 0, area: 0 });
  }

  const handleEdit = () => {
    setEditable(!editable);
    if (!needUpdate)
      return;
    updateBoard()
  }

  return (
    <>
      {
        showModal ? <ModalWidget display={showModal} addToLayout={addToLayout}
          hideModal={hideModal}
          currentLayout={getStaticLayout(tmpLayout.lg)}
          maxDim={maxDim} data={data} /> : null
      }
      <div style={style} className="modular__grid-container">
        <div className="modular__grid-infos">
          <div className="grid__title-container">
            <div className="grid__title">
              <h3 className="modular__grid-title">{boardInfos.cityName}</h3>
              <button className="modular__grid-edit" onClick={handleEdit}>
                <img className="modular__grid-icon"
                  src={editable ? validButton : editButton}
                  alt="edit grid" />
              </button>
            </div>
            {editable ? <RedButton content={"Supprimer"} action={(ev) => {
              ev.preventDefault();
              deleteBoard(boardId);
            }
            } /> : null
            }
          </div>
          <p className="modular__grid-subtitle">
            {`${boardInfos.coords.long}, ${boardInfos.coords.lat}`}
          </p>
        </div>
        <ResponsiveGridLayout className="editable__grid" layouts={tmpLayout}
          allowOverlap={false} cols={{ lg: 6, md: 4, xs: 2 }}
          breakpoints={{ xs: 1, md: 480, lg: 948 }}
          margin={[16, 16]}
          containerPadding={[16, 16]}
          rowHeight={162} isResizable={false}
          isDraggable={false}
          isBounded={true} useCSSTransforms={true}>
          {tmpLayout.lg.map(el => createElement(el))}
        </ResponsiveGridLayout>
      </div>
    </>
  )
}

export default EditableGrid;
