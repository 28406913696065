import React, { FunctionComponent } from "react";
import "./widget.css";
import "./airQualSmall.css"
import { weatherData } from "../../types/weather";


type Props = {
  data?: weatherData;
};

const AirQualSmallWidget: FunctionComponent<Props> = (props: Props) => {

  const airQualData = [{
    color: "#9BD74E", label: "Bonne", position: "95%"
  },
  {
    color: "#F8BA3E", label: "Correcte", position: "72.5%"
  },
  {
    color: "#F78E49", label: "Moyenne", position: "45%"
  },
  {
    color: "#D06685", label: "Mauvaise", position: "27.5%"
  },
  {
    color: "#9F6D94", label: "Exécrable", position: "5%"
  },
  ]

  if (props.data === undefined || props.data?.actual.airQual.index === undefined) {
    return <></>
  }

  return (
    <>
      <div>
        <span className="widget-title">Qualité de l'air</span>
        <div className="widget-visibility-gradient"><div className="widget-visibility-cursor" style={{ background: airQualData[props.data.actual.airQual.index - 1].color, left: airQualData[props.data.actual.airQual.index - 1].position }}></div></div>
      </div>
      <div><span className="widget-air-qual-data">{6 - props.data.actual.airQual.index}</span><span className="widget-air-qual-unit">/5</span><span /></div>
      <span className="widget-air-qual-footer">{airQualData[props.data.actual.airQual.index - 1].label}</span>
    </>
  )
}

export default AirQualSmallWidget;
