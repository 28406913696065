import React, {
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState
} from "react";
import PrimaryButton from "./buttons/primaryButton";
import SecondaryButton from "./buttons/secondaryButton";
import {Link, useLocation} from "react-router-dom";
import "./navbar.css";
import Logo from "../svg/zephyr_logo.svg";
import {useSelector} from "react-redux";
import {getUserConnected} from "../redux/userSelector";

type menuProps = {
  children: ReactNode;
  display?: string;
};

type linkProps = {
  name: string;
  link: string;
  active: string;
  animate?: MouseEventHandler;
};

type hamburgerProps = {
  animate: MouseEventHandler;
};

const Hamburger: FunctionComponent<hamburgerProps> = ({animate}) => {

  return (
    <button className="menu" onClick={animate} aria-label="Main Menu">
      <svg width="32" height="32" viewBox="0 0 100 100">
        <path
          className="line line1"
          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
        />
        <path className="line line2" d="M 20,50 H 80"/>
        <path
          className="line line3"
          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
        />
      </svg>
    </button>
  );
};

const Menu: FunctionComponent<menuProps> = ({
                                              children,
                                              display = "navbar__container"
                                            }) => {
  const [status, setStatus] = useState<string>(display);

  useEffect(() => {
    setStatus(display);
  }, [display]);

  return <div id="navbar__container" style={{display: "none"}}
              className={status}>{children}</div>;
};

const MenuLink: FunctionComponent<linkProps> = ({
                                                  name,
                                                  link,
                                                  active,
                                                  animate
                                                }) => {
  const isActive = (): boolean => {
    return active.split('/')[1] === link.split('/')[1];
  }
  return (
    <li className="navbar__container--li" onClick={animate}>
      <Link className={`navbar__container--link ${isActive() ? "active" : ""}`}
            to={link}>{name}</Link>
    </li>
  );
};

const UserNavbarMobile: FunctionComponent = () => {
  const userConnected = useSelector(getUserConnected);
  const token = localStorage.getItem("token");
  return (
    <>
      {!token || !userConnected ?
        <>
          <PrimaryButton content="Connexion" url="/login"/>
          <SecondaryButton content="Inscription" url="/register"/>
        </>
        :
        <Link to="/account" className="account__link">
          <p>{userConnected.name} {userConnected.lastname[0]}.</p>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 10C12.42 10 16 11.79 16 14V16H0V14C0 11.79 3.58 10 8 10Z"
              fill="#022B3A"/>
          </svg>
        </Link>}
    </>
  )
}

const UserNavbarDesktop: FunctionComponent = () => {
  const userConnected = useSelector(getUserConnected);
  const token = localStorage.getItem("token");
  return (
    <>
      {!token || !userConnected ? <> <Link className="navbar__connectLink"
                                         to="/login">Connexion</Link>
          <PrimaryButton content="Inscription" url="/register"
                         style={{padding: ".5rem 1rem"}}/> </>
        :
        <Link to="/account" className="account__link">
          <p>{userConnected.name} {userConnected.lastname[0]}.</p>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 10C12.42 10 16 11.79 16 14V16H0V14C0 11.79 3.58 10 8 10Z"
              fill="#022B3A"/>
          </svg>
        </Link>}
    </>
  )
}

const NavbarMobile: FunctionComponent = () => {
  const [display, setDisplay] = useState<string>("navbar__container");
  const location = useLocation();
  const [active, setActive] = useState("/board");

  useEffect(() => {
    setActive(location.pathname);
  }, [location])

  const animate = () => {
    const menu = document.querySelector(".menu");

    if (!menu) return false;
    menu.classList.toggle("opened");
    menu.setAttribute("aria-expanded", `${menu.classList.contains("opened")}`);
    toggleDisplay();
  };

  const toggleDisplay = () => {
    const root: HTMLElement | null = document.body.querySelector("#root");
    const menu: HTMLElement | null = document.body.querySelector("#navbar__container");
    // console.log('toogle display')
    if (!menu || !root) return;
    // console.log(display)
    if (display === "navbar__container") {
      menu.style.display = "flex";
      setDisplay("navbar__container show");
      root.style.overflowX = "hidden";
    } else {
      setDisplay("navbar__container");
      setTimeout(() => {
        menu.style.display = "none"
      }, 200);
      root.style.removeProperty("overflow");
    }
  };

  return (
    <nav className="navbar">
      <Link to="/">
        <img className="navbar__logo" src={Logo} alt="logo"/>
      </Link>
      <Hamburger animate={animate}/>
      <Menu display={display}>
        <ul className="navbar__container--ul">
          <MenuLink active={active} key={0} name="Accueil"
                    link="/" animate={animate}/>
          <li className="navbar__separator"></li>
          <MenuLink active={active} key={1} name="Tableau de bord"
                    link="/board" animate={animate}/>
          <li className="navbar__separator"></li>
          <MenuLink active={active} key={2} name="Météo" link="/weather"
                    animate={animate}/>
          <li className="navbar__separator"></li>
          <MenuLink active={active} key={3} name="Qualité de l'air"
                    link="/air-quality" animate={animate}/>
          <li className="navbar__separator"></li>
          <MenuLink active={active} key={4} name="Bilan carbone"
                    link="/carbon-footprint" animate={animate}/>
        </ul>
        <div className="navbar__container--button" onClick={animate}>
          <UserNavbarMobile/>
        </div>
      </Menu>
    </nav>
  );
};

const NavbarDesktop: FunctionComponent = () => {
  const location = useLocation();
  const [active, setActive] = useState("/board");
  useEffect(() => {
    setActive(location.pathname);
  }, [location])
  return (
    <nav className="navbar">
      <Link to="/">
        <img className="navbar__logo" src={Logo} alt="logo"/>
      </Link>
      <ul className="navbar__container--ul">
        <MenuLink active={active} key={0} name="Accueil"
                  link="/"/>
        <li className="navbar__separator"></li>
        <MenuLink active={active} key={1} name="Tableau de bord" link="/board"/>
        <li className="navbar__separator"></li>
        <MenuLink active={active} key={2} name="Météo" link="/weather"/>
        <li className="navbar__separator"></li>
        <MenuLink active={active} key={3} name="Qualité de l'air"
                  link="/air-quality"/>
        <li className="navbar__separator"></li>
        <MenuLink active={active} key={4} name="Bilan carbone"
                  link="/carbon-footprint"/>
      </ul>
      <div className="navbar__container--button">
        <UserNavbarDesktop/>
      </div>
    </nav>
  );
};

const Navbar: FunctionComponent = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const windowSizeHandler = (_ev: UIEvent) => {
    setWidth(window.innerWidth);
  };

  window.addEventListener("resize", windowSizeHandler);

  return <>{width < 950 ? <NavbarMobile/> : <NavbarDesktop/>}</>;
};

export default Navbar;
