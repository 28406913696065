import React, { FunctionComponent } from "react";
import "./widget.css";
import "./visibility.css"
import { weatherData } from "../../types/weather";

type Props = {
  data?: weatherData;
};

const VisibilityWidget: FunctionComponent<Props> = (props: Props) => {

  function formatVisibility(visibilityNb?: number): String {
    if (visibilityNb === undefined) {
      return "N/A";
    }
    return (visibilityNb / 1000).toFixed(1);
  }

  return (
    <>
      <span className="widget-title">Visibilité</span>
      <div>
        <span className="widget-visibility-data" >{formatVisibility(props.data?.actual.airQual.visibility) + " "}</span><span className="widget-visibility-unit">km</span>
      </div>
      <div className="widget-footer"></div>
    </>
  )
}

export default VisibilityWidget;
