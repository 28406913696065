import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import PrimaryButton from "../components/buttons/primaryButton";
import Navbar from "../components/navbar";
import "./validateAccount.css";
import Footer from "../components/footer";
import success from "../icons/valid-icon.svg";
import error from "../icons/invalid_icon.svg";
import Loader from "../components/loader";

const ValidateAccount: FunctionComponent = () => {
  const [queryParameters] = useSearchParams();
  const [status, setStatus] = useState<boolean | undefined>(undefined);
  const [load, setLoad] = useState<boolean>(false);
  const navigate = useNavigate();

  const sendValidation = async () => {
    try {
      setLoad(true);
      const token = queryParameters.get("token");
      if (!token) {
        setStatus(false);
        return;
      }
      const response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/validate-account/${token}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        mode: "cors",
      });
      setLoad(false);
      if (!response.ok) {
        setStatus(false)
        return;
      }
      setStatus(true)
    } catch (e) {
      console.log(e)
      setStatus(false)
    }
  }

  const redirect = () => {
    navigate("/login");
  }

  useEffect(() => {
    const div: HTMLDivElement | null = document.querySelector('#root');
    if (!div)
      return;
    div.style.height = "100%";
    return () => {
      div.style.height = "auto";
    }
  }, []);

  if (status === undefined)
    return (
      <>
        <Navbar />
        <main className="reset__main">
          <div className="background__container"></div>
          {!load ?
            <div className="confirm__container">
              <h1 className="validation__h1">Validation de l'email<span className="title__point"></span></h1>
              <p className="confirm__infos">En cliquant sur le bouton ci-dessous vous aurez accès à toutes les foncitonnalités disponibles sur Zephyr.</p>
              <PrimaryButton action={sendValidation} content="Confirmer mon email" />
            </div>
            :
            <div className="confirm__container">
              <h1 className="validation__h1">Validation de l'email<span className="title__point"></span></h1>
              <Loader />
            </div>
          }
        </main>
        <Footer />
      </>
    )

  return (
    <>
      <Navbar />
      <main className="reset__main">
        <div className="background__container"></div>
        <div className="confirm__container">
          <h1 className="validation__h1">Validation de l'email<span className="title__point"></span></h1>
          <div className="confirmation__result">
            <img className="confirmation__icon" src={status ? success : error}
                 alt="icon"/>
            <p className="confirmation__text">
              {status ? "Votre compte a été confirmé avec succès." :
                "Erreur lors de la suppression du compte, merci de réessayer plus tard."}
            </p>
          </div>
          <PrimaryButton action={status ? redirect : sendValidation} content={status ? "Connexion" : "Réessayer"} />
        </div>
      </main>
      <Footer />
    </>
  )
}

export default ValidateAccount;
