import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import Footer from "./components/footer";
import NavBar from "./components/navbar";
import UserService from "./services/user.service";
import {getTokenUser} from "./helpers/isUserConnected";

function App() {
  const userService = new UserService();
  useEffect(() => {
    console.log("check if user is connected");
    const token = localStorage.getItem('token');
    if (!token)
      return;
    getTokenUser().then(userId => {
      if (!userId)
        return;
      userService.getUserById(token, userId)
    });
  }, []);

  return (
    <>
      <NavBar/>
      <Outlet/>
      <Footer/>
    </>
  );
}

export default App;
