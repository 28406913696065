import {setUser} from "../redux/actions/userActions";
import {store} from "../redux/store";
import {User} from "../types/userType";

const USER_API_URL = `${process.env.REACT_APP_SERVER_ADDR}/user`;

export default class UserService {
  getUserById = async (token: string, id: number): Promise<number | null> => {
    try {
      console.log("fetch user")
      const response = await fetch(`${USER_API_URL}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        mode: "cors"
      });
      if (!response.ok) {
        console.log("failed to fetch user:", response)
        store.dispatch(setUser(null))
        return null;
      }
      const result = await response.json();
      store.dispatch(setUser(result));
      console.log("set user")
      return Number(result);
    } catch (e) {
      console.log("unset user")
      console.log(`Error while getting user: ${e}`);
      store.dispatch(setUser(null))
      return null;
    }
  }

  updateUser = async (token: string, user: User) => {
    try {
      console.log(user)
      const payload = {
        userId: user.id,
        name: user.name,
        lastname: user.lastname,
        residenceCity: user.residenceCity,
        email: user.email,
        lat: 0,
        long: 0
      }
      const response = await fetch(`${USER_API_URL}/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        mode: "cors",
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        store.dispatch(setUser(null))
        console.log("unset user")
        return;
      }
      store.dispatch(setUser(user));
    } catch (e) {
      console.log(`Error while updating user: ${e}`);
      store.dispatch(setUser(null))
    }
  }

  deleteUser = async (token: string, user: User): Promise<boolean> => {
    try {
      const payload = {
        userId: user.id
      }
      const response = await fetch(`${USER_API_URL}/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        mode: "cors",
        body: JSON.stringify(payload)
      });
      return response.ok;
    } catch (e) {
      console.log(`Error while deleting user: ${e}`);
      return false;
    }
  }
}
