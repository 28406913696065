import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter, Navigate,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import "./css/reset.css";
import "./css/root.css";
import AirQuality from "./pages/airQuality";
import Board, {boardQueryResut} from "./pages/board";
import ErrorPage from "./pages/error";
import Home from "./pages/home";
import Login from "./pages/login";
import Register from "./pages/register";
import Weather from "./pages/weather";
import WeatherResults from "./pages/weatherResults";
import CarbonFootprint from "./pages/carbonFootprint";
import ProtectedRoute from "./helpers/isUserConnected";
import jwtDecode from "jwt-decode";
import {authToken} from "./helpers/isUserConnected";
import {checkBoardQueryType} from "./types/checkBoardQueryType";
import Account from "./pages/account";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import ResetPassword from "./pages/resetPassword";
import ResetPasswordConfirm from "./pages/resetPasswordConfirm";
import UpdatePassword from "./pages/updatePassword";
import DataPolicy from "./pages/dataPolicy";
import ValidateAccount from "./pages/validateAccount";
import ThanksRegister from "./pages/thanksRegister";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "",
        element: <Home/>,
      },
      {
        path: "board",
        element: <Board/>,
      },
      {
        path: "weather",
        element: <Weather/>,
      },
      {
        path: "weather/results",
        element: <WeatherResults/>,
      },
      {
        path: "air-quality",
        element: <AirQuality/>,
      },
      {
        path: "carbon-footprint",
        element: <CarbonFootprint/>,
      },
      {
        path: "account",
        element: <ProtectedRoute target="/account"><Account/></ProtectedRoute>
      }
    ],
  },
  {path: "login", element: <Login/>},
  {path: "register", element: <Register/>},
  {path: "reset-password", element: <ResetPassword/>},
  {path: "reset-password-confirm", element: <ResetPasswordConfirm />},
  {path: "update-password", element: <UpdatePassword/>},
  {path: "data-policy", element: <DataPolicy/>},
  {path: "validate-account", element: <ValidateAccount />},
  {path: "confirm-register", element: <ThanksRegister />}
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router}/>
    </Provider>
  </React.StrictMode>
);
