import React, {FunctionComponent, useEffect, useState} from "react";
import "./passwordChecker.css"

const haveMaj = (pass: string): boolean => {
  return (/[A-Z]+/.exec(pass) !== null);
}

const haveMin = (pass: string): boolean => {
  return (/[a-z]+/.exec(pass) !== null);
}

const haveNumber = (pass: string): boolean => {
  return (/[0-9]+/.exec(pass) !== null);
}

const haveSpecial = (pass: string): boolean => {
  return (/[@$!%*?&]/.exec(pass) !== null);
}

const boolToNumber = (bool: boolean): 0 | 1 => {
  return bool ? 1 : 0;
}

type props = {
  pass: string
};
const PasswordChecker: FunctionComponent<props> = ({pass}) => {
  const [progress, setProgress] = useState(0);
  const [heightChar, setHeightChar] = useState(false);
  const [majAndMin, setMajAndMin] = useState(false);
  const [digit, setDigit] = useState(false);
  const [special, setSpecial] = useState(false);

  useEffect(() => {
    setHeightChar(pass.length >= 8);
    setMajAndMin(haveMaj(pass) && haveMin(pass));
    setDigit(haveNumber(pass));
    setSpecial(haveSpecial(pass));
  }, [pass]);

  useEffect(() => {
    setProgress(boolToNumber(heightChar) + boolToNumber(majAndMin) + boolToNumber(digit) + boolToNumber(special));
  }, [heightChar, majAndMin, digit, special])

  return (
    <div className="password__checker">
      <div className="progress__bar">
        <div id="progress1"
             className={`progress__bar-item ${progress >= 1 ? "show" : ""}`}></div>
        <div id="progress2"
             className={`progress__bar-item ${progress >= 2 ? "show" : ""}`}></div>
        <div id="progress3"
             className={`progress__bar-item ${progress >= 3 ? "show" : ""}`}></div>
        <div id="progress4"
             className={`progress__bar-item ${progress >= 4 ? "show" : ""}`}></div>
      </div>
      <div className="password__indication-container">
        <p className={`password__indication ${heightChar ? "valid" : ""}`}>Au
          moins 8 caractères</p>
        <p className={`password__indication ${majAndMin ? "valid" : ""}`}>Une
          majuscule et une minuscule</p>
        <p
          className={`password__indication ${digit ? "valid" : ""}`}>Au
          moins un chiffre</p>
        <p
          className={`password__indication ${special ? "valid" : ""}`}>Au moins
          un caractère spécial</p>
      </div>
    </div>
  )
}

export default PasswordChecker;
