import React, {FunctionComponent} from "react";
import Title from "../components/text/title";

const AirQuality: FunctionComponent = () => {
  return (
    <main className="weather_main">
      <div className="title_container">
        <Title title="Qualité de l'air"/>
        <h3 className="title_container__subtitle">Tous les indicateurs de la
          qualité de votre air.</h3>
      </div>
      <img src="img/in_building.svg" alt="Construction barrier"
           className="build_img"/>
      <h3 className="build_text">En construction !</h3>
    </main>
  )
}

export default AirQuality;
