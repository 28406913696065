import React, { FunctionComponent } from "react";
import "./widget.css";
import "./weekForecast.css"
import { weatherData } from "../../types/weather";
import Icon from "../icons/weatherIcon";

type Props = {
  data?: weatherData;
};

const WeekForecastWidget: FunctionComponent<Props> = (props: Props) => {

  function getRows() {
    const rows : JSX.Element[] = [];
    if (props.data?.day7 !== undefined) {
      for (let i = 0; i < 7; i++) {
        rows.push(
          <div className="widget-weekforecast__body--row" key={i}>
            <span className="widget-weekforecast__body--row__day">{props.data.day7[i].info.day}</span>
            <Icon class="widget-weekforecast__body--row__icon" weatherCode={props.data.day7[i].weather.weatherType} color={1}/>
            <span className="widget-weekforecast__body--row__temp">{props.data.day7[i].weather.tempMax.toFixed(0)}°</span>
          </div>
        )
      }
    }
    return rows;
  }

  return (
    <>
      <span className="widget-title">Prévisions</span>
      <div className="widget-weekforecast__separator"></div>
      <div className="widget-weekforecast__body">
        {getRows()}
      </div>
    </>
  )
}

export default WeekForecastWidget;
