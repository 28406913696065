import clearNight from "../images/clearNight.png"
import cloud from "../images/cloud.png"
import cloudNight from "../images/cloudNight.png"
import fog from "../images/fog.png"
import heavyCloud from "../images/heavyCloud.png"
import heavyRain from "../images/heavyRain.png"
import lightCloud from "../images/lightCloud.png"
import lightCloudNight from "../images/lightCloudNight.png"
import lightRain from "../images/lightRain.png"
import snow from "../images/snow.png"
import sun from "../images/sun.png"
import thunderstorm from "../images/thunderstorm.png"
import {weather} from "../types/weather"

const imageTab = {
  0: sun,
  1: lightCloud,
  2: cloud,
  3: heavyCloud,
  4: lightRain,
  5: heavyRain,
  6: thunderstorm,
  7: fog,
  8: snow,
  9: clearNight,
  10: lightCloudNight,
  11: cloudNight,
}

function getImage(weatherCode?: weather): string {
  if (!weatherCode)
    return imageTab[0];
  return imageTab[weatherCode];
}

export {getImage}
