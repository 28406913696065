import {createReducer} from "@reduxjs/toolkit";
import {UserStateReducer} from "../../types/userType";
import {setUser} from "../actions/userActions";

const initialState: UserStateReducer = {
  user: null
};

export const userReducers = createReducer(initialState, builder => {
  builder.addCase(setUser, (_state, action) => {
    // console.log(action.payload)
    return {
      user: action.payload
    }
  })
  // builder.addCase(unsetUser, (_state, _action) => {
  //   console.log("unset user === null")
  //   return {
  //     user: null
  //   }
  // })
})
