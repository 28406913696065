import React, {FunctionComponent} from "react";
import "./loader.css";

const Loader: FunctionComponent = () => {
  return (
    <div className="loader__container">
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none"
           xmlns="http://www.w3.org/2000/svg"
           className="loader__component">
        <path className="line1"
              d="M34.2858 9.92473C34.2858 6.65786 31.4839 4 28.0399 4H26.9631V7.06452H28.0399C29.7025 7.06452 31.0552 8.34763 31.0552 9.92473C31.0552 11.5018 29.7025 12.7849 28.0399 12.7849H0.148926V15.8495H28.0399C31.4839 15.8495 34.2858 13.1917 34.2858 9.92473Z"
              fill="#1F7A8C"/>
        <path className="line2"
              d="M36.1165 30.1504H0.148926V33.2149H36.1165C37.7791 33.2149 39.1317 34.498 39.1317 36.0751C39.1317 37.6522 37.7791 38.9353 36.1165 38.9353H35.0396V41.9999H36.1165C39.5604 41.9999 42.3624 39.342 42.3624 36.0751C42.3624 32.8082 39.5604 30.1504 36.1165 30.1504Z"
              fill="#1F7A8C"/>
        <path className="line3"
              d="M49.1467 21.4678H14.7944V24.5323H31.9706H49.1467V21.4678Z"
              fill="#1F7A8C"/>
        <path className="line4"
              d="M11.5638 21.4678H0V24.5323H11.5638V21.4678Z"
              fill="#1F7A8C"/>
      </svg>
    </div>
  )
}

export default Loader;
