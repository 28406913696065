import React, {CSSProperties, FunctionComponent} from "react";
import "./primaryButton.css";

type Props = {
  content: String;
  url?: string;
  action?: React.MouseEventHandler<HTMLAnchorElement>;
  style?: CSSProperties
};

const PrimaryButton: FunctionComponent<Props> = (props) => {
  return (
    <a href={props.url} className="primary-button" style={props.style}
       onClick={props.action}>
      <span className="primary-button__content">{props.content}</span>
    </a>
  );
}

export default PrimaryButton;
