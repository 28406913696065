import React, {FunctionComponent} from "react";
import Title from "../components/text/title";

const CarbonFootprint: FunctionComponent = () => {
  return (
    <main className="weather_main">
      <div className="title_container">
        <Title title="Bilan carbone"/>
        <h3 className="title_container__subtitle">Ne respirez pas les pots
          d’échappements !</h3>
      </div>
      <img src="img/in_building.svg" alt="Construction barrier"
           className="build_img"/>
      <h3 className="build_text">En construction !</h3>
    </main>
  )
}

export default CarbonFootprint;
