import React, {FunctionComponent} from "react";
import "./redButton.css";

type Props = {
  content: String;
  url?: string;
  action?: React.MouseEventHandler<HTMLAnchorElement>;
};

const RedButton: FunctionComponent<Props> = (props) => {
  return (
    <a href={props.url} className="red-button" onClick={props.action}>
      <span className="red-button__content">{props.content}</span>
    </a>
  );
}

export default RedButton;
