import React, {FunctionComponent, ReactNode} from "react";
import "./widget.css";
import "./airInfos.css"
import {weatherData} from "../../types/weather";
import Compass from "./components/compass";
import BarometerComponent from "./components/barometer";

type Props = {
  data?: weatherData;
};

const AirInfosWidget: FunctionComponent<Props> = (props: Props) => {

  if (!props.data) {
    return <></>;
  }

  const airQualData = [{
    color: "#9BD74E", label: "Bonne", position: "5%"
  },
    {
      color: "#F8BA3E", label: "Correcte", position: "27.5%"
    },
    {
      color: "#F78E49", label: "Moyenne", position: "45%"
    },
    {
      color: "#D06685", label: "Mauvaise", position: "72.5%"
    },
    {
      color: "#9F6D94", label: "Exécrable", position: "95%"
    },
  ]

  const airQualGrades = [
    [[0, 10], [10, 25], [25, 50], [50, 75], [75, Infinity]], // PM25
    [[0, 40], [40, 70], [70, 150], [150, 200], [200, Infinity]], // NO2
    [[0, 20], [20, 50], [50, 100], [100, 200], [200, Infinity]], //PM10
    [[0, 60], [60, 100], [100, 140], [140, 180], [180, Infinity]], //03
    [[0, 4400], [4400, 9400], [9400, 12400], [12400, 15400], [15400, Infinity]], //CO
    [[0, 20], [20, 80], [80, 250], [250, 350], [350, Infinity]], //SO2
  ]

  const graphColors: Array<string> = ["#66CC00", "#F6CE39", "#F78E49", "#E56170", "#B16CA4"]

  function getColColor(index: number, value: number): string {
    const data = airQualGrades[index];
    for (let i = 0; i < data.length; i++) {
      if (data[i][0] <= value && data[i][1] >= value) {
        return graphColors[i];
      }
    }
    return '#000000'
  }

  function getGraphColumns(index: number, value: number, unit: string): ReactNode {
    const height: number = (value / airQualGrades[index][4][0] < 1 ? (value / airQualGrades[index][4][0] * 80) + 20 : 100)
    const margin: number = 100 - height;
    return (
      <div key={index} className="widget-air-infos__air-qual--graph__column">
        <div className="widget-air-infos__air-qual--graph__column--bar" style={{
          background: `${getColColor(index, value)}`,
          height: `${height}px`,
          marginTop: `${margin}px`
        }}></div>
        <span
          className="widget-air-infos__air-qual--graph__column--unit">{unit}</span>
      </div>
    )
  }

  function getGrah() {
    if (!props.data) {
      return <></>
    }

    let graph: Array<ReactNode> = [];
    graph.push(getGraphColumns(0, props.data.actual.airQual.PM25, "PM2.5"))
    graph.push(getGraphColumns(1, props.data.actual.airQual.NO2, "NO2"))
    graph.push(getGraphColumns(2, props.data.actual.airQual.PM10, "PM10"))
    graph.push(getGraphColumns(3, props.data.actual.airQual.O3, "O3"))
    graph.push(getGraphColumns(4, props.data.actual.airQual.CO, "CO"))
    graph.push(getGraphColumns(5, props.data.actual.airQual.SO2, "SO2"))
    return graph;
  }


  return (
    <>
      <div className="widget-air-infos__air-qual">
        <div className="widget-air-infos__air-qual--graph">
          {getGrah()}
        </div>
        <div className="widget-air-infos__air-qual--separator"></div>
        <div className="widget-air-infos__air-qual--grade">
          <div><span
            className="widget-air-infos__air-qual--data">{6 - props.data.actual.airQual.index}</span><span
            className="widget-air-infos__air-qual--unit">/5</span><span/></div>
          <span
            className="widget-air-infos__air-qual--footer">{airQualData[props.data.actual.airQual.index - 1].label}</span>
        </div>
      </div>
      <div className="widget-air-infos__separator"></div>
      <div className="widget-air-infos__wind">
        <div className="widget-air-infos__wind--compass">
          <Compass orientation={props.data.actual.wind.orientation}/>
          <div><span
            className="widget-wind-data">{props.data?.actual.wind.speed ? Math.round(props.data?.actual.wind.speed) : "N/A"}</span><span
            className="widget-wind-unit"> km/h</span><span/></div>
        </div>
        <div className="widget-air-infos__wind--barometer">
          <BarometerComponent data={props.data}/>
        </div>
      </div>
    </>
  )
}

export default AirInfosWidget;
