import {FunctionComponent} from "react";
import {weather} from "../../types/weather";
import "./smallWeatherInfo.css";
import Icon from "../icons/weatherIcon";

type Props = {
  class?: string;
  weatherInfos: {
    weather: {
      weatherType: weather,
      tempMin: number,
      tempMax: number,
    }
    rain: {
      sum: number,
    }
    info: {
      day: string,
      date: string
    }
  };
};

const SmallWeatherInfo: FunctionComponent<Props> = (props: Props) => {

  return (
    <div className={`small-weather ${props.class}`}>
      <p className="small-weather__day">{props.weatherInfos.info.day}</p>
      <div className="small-weather__data">
        <p>{props.weatherInfos.weather.tempMax.toFixed(0)}°</p>
        <Icon color={1} weatherCode={props.weatherInfos.weather.weatherType}
              class="small-weather__data-icon"></Icon>
      </div>
    </div>
  )
}

export default SmallWeatherInfo;
