import React, {FunctionComponent} from "react";
import Title from "../components/text/title";
import CitySearchBar from "../components/citySearchBar";
import "./home.css";
import "../css/baseTitle.css";
import SecondaryButton from "../components/buttons/secondaryButton";
import Card from "../components/card";
import PrimaryButton from "../components/buttons/primaryButton";

import CardWeatherImg from "../images/card_weather.png";
import CardPersoImg from "../images/card_perso.png";
import CardAirQualImg from "../images/card_airqual.png";
import CardCarbonImg from "../images/card_carbon.png";

import PhoneMockup from "../images/iphoneMockup.png";
import DeskMockup from "../images/window_mockup.png";

const Home: FunctionComponent = () => {
  return (
    <main className="home_main">
      <header className="home_header section__content">
        <div className="header_main">
          <div className="title_container">
            <Title title="Zephyr"/>
            <h3 className="title_container__subtitle">Une météo ? La vôtre.</h3>
          </div>
          <div className="searchBar_container">
            <CitySearchBar url="/weather/results"/>
          </div>
          <div className="button_container">
            <SecondaryButton content="A propos" url="#about"/>
          </div>
        </div>
        <img src={DeskMockup} className="header_window"
             alt="Exemple of Zephyr search result"></img>
      </header>
      <img src={PhoneMockup} className="home_phone"
           alt="Exemple of Zephyr search result"></img>
      <div className="about" id="about">
        <div className="section__content about__section">
          <div className="about__title">
            <Title title="Zephyr la météo centralisée"/>
          </div>
          <p className="about__text">Zephyr est un projet développé par deux
            étudiants d’EPITECH Bordeaux depuis septembre 2022. Il est conçu
            comme
            un outil moderne de prédiction météo centralisée et personnelle qui
            vous permet d’organiser et de sélectionner les données qui comptent
            le
            plus pour vous. Ainsi vous pourrez, grâce à un large choix
            d’indicateurs allant de la température actuelle jusqu’à la
            concentration des principaux agents polluants de l’air, composer
            votre
            propre tableau de bord. Zephyr est donc un outil du quotidien qui
            vous
            permettra d’appréhender un peu mieux les changements climatiques
            contemporains.</p>
          <p className="about__text">Zephyr est un projet à but non lucratif qui
            peut être vu comme un agrégateur de données météorologiques. Il vous
            permet d’obtenir en un seul et même point des données précises et
            variées mises en forme grâce aux différents outils du site, afin de
            garantir une accessibilité et une lisibilité maximum.</p>
          <div className="about__link">
            <PrimaryButton content="Découvrir les outils" url="#func"/>
          </div>
        </div>
      </div>
      <div className="func section__content" id="func">
        <div className="func__title">
          <Title title="Fonctionnalités"/>
        </div>
        <div className="func__cards">
          <Card title="Indicateurs personnalisés"
                content="Un espace dédié pour chacune de vos villes favorites, personnalisable avec un large choix d'indicateurs. Créez votre tableau de bord et remplissez le avec les widgets qui vous correspondent."
                url="/board" imageUrl={CardPersoImg}></Card>
          <Card title="Météo"
                content="Besoin d’informations précises et détaillées sur une ville en particulier ? Grâce à cet outil vous trouverez rapidement ce que vous recherchez."
                url="/weather" imageUrl={CardWeatherImg}></Card>
          <Card title="Qualité de l’air"
                content="Un aperçu complet avec de nombreux indicateurs sur la qualité de l’air environnante ou celle de la ville recherchée."
                url="/air-quality" imageUrl={CardAirQualImg}></Card>
          <Card title="Bilan carbone"
                content="Envie de connaitre votre impact sur les émissions de gaz à effet de serre ? Zephyr met à votre disposition un calculateur de bilan carbone sous forme de questionnaire dynamique."
                url="/carbon-footprint" imageUrl={CardCarbonImg}></Card>
        </div>
      </div>
    </main>
  )
}

export default Home;
