import React, {FunctionComponent} from "react";
import "./card.css";
import Arrow from "../svg/arrow.svg";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  content: string;
  imageUrl: string;
  url: string;
};

const Card: FunctionComponent<Props> = (props: Props) => {

  return (
    <div className="card">
      <img className="card__img" src={props.imageUrl} alt="nature img"></img>
      <div className="card__text-container">
        <h3 className="card__text-container--title">
          {props.title}
        </h3>
        <p className="card__text-container--subtitle">
          {props.content}
        </p>
      </div>
      <a className="card__link" href={props.url}>
        <img className="card__link--image" src={Arrow}
             alt="fleche_lien"/>
      </a>
    </div>
  )
}

export default Card;
