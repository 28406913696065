import { FunctionComponent, useEffect } from "react";
import Navbar from "../components/navbar";
import Title from "../components/text/title";
import "./resetPasswordConfirm.css"
import Check from "../svg/check.svg";

const ResetPasswordConfirm: FunctionComponent = () => {
    useEffect(() => {
        const div: HTMLDivElement | null = document.querySelector('#root');
        if (!div)
            return;
        div.style.height = "100%";
        return () => {
            div.style.height = "auto";
        }
    }, []);

    return (
        <>
            <Navbar />
            <main className="reset__main">
                <div className="background__container"></div>
                <div className="reset-confirm__container">
                    <div className="reset-confirm__title">
                        <Title title="Réinitialiser le mot de passe" />
                    </div>
                    <img src={Check} alt="A simple check" className="reset-confirm__check" />
                    <span className="reset-confirm__sent">Email envoyé.</span>
                    <span className="reset-confirm__chek-mail">Ce dernier peut mettre un peu de temps à arriver.</span>
                    <span className="reset-confirm__chek-mail">Surveillez votre boîte mail.</span>
                </div>
            </main>
        </>
    )
}

export default ResetPasswordConfirm;
