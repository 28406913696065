import React, { FunctionComponent } from "react";
import "./widget.css";
import "./barometer.css"
import type { weatherData } from "../../types/weather";
import BarometerComponent from "./components/barometer";
type Props = {
  data?: weatherData;
};

const BarometerWidget: FunctionComponent<Props> = (props: Props) => {

  return (
    <>
      <span className="widget-title">Pression</span>
      <div className="widget-barometer">
        <BarometerComponent data={props.data}/>
      </div>
    </>
  )
}

export default BarometerWidget;
