import { FunctionComponent, useEffect } from "react";
import Navbar from "../components/navbar";
import Title from "../components/text/title";
import "./dataPolicy.css"
import { Link } from "react-router-dom";

const DataPolicy: FunctionComponent = () => {
    useEffect(() => {
        const div: HTMLDivElement | null = document.querySelector('#root');
        if (!div)
            return;
        div.style.height = "100%";
        return () => {
            div.style.height = "auto";
        }
    }, []);

    return (
        <>
            <Navbar />
            <main className="reset__main">
                <div className="background__container--data"></div>
                <div className="data-policy__container">
                    <div className="data-policy__title">
                        <Title title="Politique de gestion des données" />
                    </div>
                    <div className="data-policy__content">
                        <h3>Données collectées :</h3>
                        <p>Les seules données personnelles collectées par Zephyr sont votre nom, votre prénom, votre email et votre ville de résidence.</p>
                            <p>Aucun cookies ne sont présents sur le site pour collecter quelconque donnée personelle.</p>
                        <h3>Accès à vos données :</h3>
                        <p>Vous pouvez à tout moment modifier vos informations personnelles depuis la <Link  className="data-policy__content" to="/account">page de gestion de votre compte</Link>.</p>
                            <p> Vous pouvez également supprimer votre compte et toutes les informations qui sont liées à ce dernier depuis la <Link  className="data-policy__content" to="/account">page de gestion de votre compte</Link>.</p>
                            <p> Nous ne conservons à ce moment là aucune de vos données personnelles, ces dernières sont supprimées à l'instant où vous supprimez votre compte.</p>
                        <h3>Utilisation de vos données :</h3>
                        <p>Sur Zephyr, vos données sont utilisées uniquement à des fins d'information et de personnalisation des différentes pages de Zephyr ainsi que des emails que vous pourriez éventuellement recevoir.</p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default DataPolicy;
