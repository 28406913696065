import { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import Navbar from "../components/navbar";
import Title from "../components/text/title";
import { useNavigate } from "react-router-dom";
import "./resetPassword.css"
import Modale from "../components/modales/modale";

type ToSend = {
    email: string;
};

type Field = {
    value: string;
    isValid: boolean;
    error?: string;
};

type Form = {
    user: Field;
};

type ErrorResponse = {
    msg: string;
};

const ResetPassword: FunctionComponent = () => {


    const navigate = useNavigate();
    const [form, setForm] = useState<Form>({
        user: { value: "", isValid: false },
    });
    const [err, setErr] = useState<ErrorResponse | null>(null);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErr(null);
        const send: ToSend = { email: form.user.value };
        let response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/forgot-password`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(send),
        });

        if (!response.ok) {
            setErr(await response.json());
            return;
        }
        navigate("/reset-password-confirm");
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newField: Field = { value: "", isValid: false };
        newField.value = e.currentTarget.value;
        setForm({ ...form, ...{ [e.currentTarget.name]: newField } });
    };

    useEffect(() => {
        const div: HTMLDivElement | null = document.querySelector('#root');
        if (!div)
            return;
        div.style.height = "100%";
        return () => {
            div.style.height = "auto";
        }
    }, []);

    return (
        <>
            <Navbar />
            <main className="reset__main">
                {err !== null ? <Modale status="fail" message={err.msg} /> : null}
                <div className="background__container"></div>
                <div className="reset__container">
                    <div className="reset__title">
                        <Title title="Réinitialiser le mot de passe" />
                    </div>
                    <p className="reset__p">Entrez votre adresse email. Si elle est répertoriée dans notre base de données, nous vous enverrons la procédure de réinitialisation.</p>
                    <form className="reset__form" onSubmit={handleSubmit}>
                        <input name="user" type="email" placeholder="john.doe@gmail.com" className="input" onChange={handleChange}
                            autoComplete="username" />
                        <button className="primary-button">Envoyer</button>
                    </form>
                </div>
            </main>
        </>
    )
}

export default ResetPassword;
