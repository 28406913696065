import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from "react";
import "./addNewBoard.css";
import SearchLongLat from "./searchLongLat";
import Title from "../text/title";

type props = {
  userID: number
  style?: CSSProperties
  addBoard: (cityName: string, long: string, lat: string) => void
}

const AddNewBoard: FunctionComponent<props> = ({style, addBoard}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState<number>();

  const handleWindowResize = () => {
    if (!wrapper.current || !wrapper.current.offsetWidth)
      return;
    if (wrapper.current.offsetWidth > 948) {
      setWrapperHeight((162 * 4) + (16 * 5));
      return;
    }
    if (wrapper.current.offsetWidth > 480) {
      setWrapperHeight((162 * 6) + (16 * 7));
      return;
    }
    setWrapperHeight((162 * 12) + (16 * 13));
  }

  useEffect(() => {
    handleWindowResize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div style={style}
         className="modular__grid-container" ref={wrapper}>
      <div className="modular__grid-infos">
        <div className="grid__title-container">
          <div className="grid__title">
            <h3 className="modular__grid-title">Nouvelle ville</h3>
          </div>
        </div>
        <p className="modular__grid-subtitle">0, 0</p>
      </div>
      <div className="editable__grid"
           style={{height: `${wrapperHeight}px`}}>
        <div className="search__container">
          <Title title="Rechercher"/>
          <SearchLongLat addBoard={addBoard}/>
        </div>
      </div>
    </div>
  )
}

export default AddNewBoard;
