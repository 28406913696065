import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import Logo from "../svg/zephyr_light.svg";
import "./footer.css";

const Footer: FunctionComponent = () => {
  return (
    <footer className="footer">
      <div className="footer__column">
        <Link className="footer__logo-container" to="/">
          <img className="footer__logo" src={Logo} alt="zephyr_logo"/>
        </Link>
        <h3 className="footer__h3">Auteurs</h3>
        <a href="https://elouan-savy.com" className="link creator">
          Elouan Savy
        </a>
        <a href="https://linkedin.com/in/hippolytedavid"
           className="link creator">
          Hippolyte David
        </a>
      </div>
      <div className="footer__column">
        <h3 className="footer__h3">Navigation</h3>
        <ul className="footer__nav-ul">
          <li className="footer__nav-li">
            <Link to="/" className="link">Accueil</Link>
          </li>
          <li className="footer__nav-li">
            <Link to="/board" className="link">Tableau de bord</Link>
          </li>
          <li className="footer__nav-li">
            <Link to="/weather" className="link">Météo</Link>
          </li>
          <li className="footer__nav-li">
            <Link to="/air-quality" className="link">Qualité de l'air</Link>
          </li>
          <li className="footer__nav-li">
            <Link to="/carbon-footprint" className="link">Bilan carbone</Link>
          </li>
        </ul>
      </div>
      <div className="footer__column">
        <ul className="footer__contact-ul">
          <li className="footer__contact-li">
            <h3 className="footer__h3">Contactez-nous</h3>
            <a className="link" href="mailto:contact@zephyr-weather.fr">
              contact@zephyr-weather.fr
            </a>
          </li>
          <li className="footer__contact-li">
            <h3 className="footer__h3">Politique de confidentialité</h3>
            <p>
              Lire la politique de{" "}
              <Link className="link" to="/data-policy">
                {" "}
                gestion des données
              </Link>
            </p>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
