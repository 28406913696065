import React, { FunctionComponent, useEffect } from "react";
import Navbar from "../components/navbar";
import "./error.css"
import Title from "../components/text/title";
import PrimaryButton from "../components/buttons/primaryButton";

const ErrorPage: FunctionComponent = () => {

  useEffect(() => {
    const div: HTMLDivElement | null = document.querySelector('#root');
    if (!div)
        return;
    div.style.height = "100%";
    return () => {
        div.style.height = "auto";
    }
  }, []);

  return (
    <>
      <Navbar />
      <main>
        <div className="error__background--container"></div>
        <div className="error__container">
          <div className="error__title">
            <Title title="404" />
          </div>
          <p className="error__p">La page à laquelle vous essayez d’acceder n’existe pas.</p>
          <PrimaryButton style={{marginTop: "1rem"}} content="Retour a l'acueil" url="/"/>
        </div>
      </main>
    </>
  )
}

export default ErrorPage;
