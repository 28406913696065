import { FunctionComponent, useEffect, useState } from "react";
import CitySearchBar from "../components/citySearchBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainWeatherInfo from "../components/weather/mainWeatherInfo";
import { weatherData } from "../types/weather";
import CityHeader from "../components/text/cityHeader";
import "./weatherResults.css"
import { Responsive, WidthProvider } from 'react-grid-layout';
import Widget from "../components/widgets/widget";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const WeatherResults: FunctionComponent = () => {

  const [searchParams] = useSearchParams();
  const [result, setResult] = useState<weatherData>();
  const cityName: string | null = searchParams.get("city");

  const navigate = useNavigate()

  async function getResults() {
    if (result !== undefined)
      return result;
    const response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/weather/city-data?lat=${searchParams.get("lat")}&lng=${searchParams.get("lng")}`, {
      method: 'GET',
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      mode: "cors",
    });
    if (!response.ok) {
      return undefined;
    }
    const weatherInfo = (await (response.json()));
    setResult(weatherInfo);
  }

  useEffect(() => {
    if (searchParams.get("lat") === null || searchParams.get("lng") === null) {
      navigate("/weather");
    }
    try {
      getResults();
    } catch (e) {
      console.log(e);
    }
  });

  const layout = {
    "mobile": [
      { i: "hourlyWeather", x: 0, y: 0, w: 2, h: 1, static: true },
      { i: "rainInHour", x: 0, y: 1, w: 1, h: 1, static: true },
      { i: "wind", x: 1, y: 1, w: 1, h: 1, static: true },
      { i: "sunRise", x: 0, y: 3, w: 2, h: 1, static: true },
      { i: "pressure", x: 0, y: 2, w: 1, h: 1, static: true },
      { i: "airQual", x: 1, y: 2, w: 1, h: 1, static: true },
      { i: "digitalHour", x: 0, y: 4, w: 1, h: 1, static: true }
    ],
    "desktop": [
      { i: "hourlyWeather", x: 0, y: 0, w: 2, h: 1, static: true },
      { i: "rainInHour", x: 2, y: 0, w: 1, h: 1, static: true },
      { i: "wind", x: 0, y: 1, w: 1, h: 1, static: true },
      { i: "pressure", x: 1, y: 1, w: 1, h: 1, static: true },
      { i: "airQual", x: 2, y: 1, w: 1, h: 1, static: true },
      { i: "sunRise", x: 1, y: 2, w: 2, h: 1, static: true },
      { i: "digitalHour", x: 0, y: 2, w: 1, h: 1, static: true }
    ]
  };

  return (
    <main className="results-container">
      <div className="results-container__searchbar">
        <CitySearchBar url="/weather/results" />
      </div>
      <div className="results-container__city-header">
        <CityHeader city={cityName}
          lat={searchParams.get("lat")}
          lng={searchParams.get("lng")} />
      </div>
      <div className="results-container__city-results">
        <div className="results-container__city-results--data">
          <MainWeatherInfo weather={result} />
          <ResponsiveReactGridLayout
            className="results-container__widgets-grid"
            layouts={layout}
            breakpoints={{ mobile: 1, desktop: 480 }}
            cols={{ mobile: 2, desktop: 3 }}
            margin={[16, 16]}
            containerPadding={[0, 0]}
            rowHeight={162}
          >
            <div className="results-container__widgets" key="hourlyWeather">
              <Widget data={result} type="tempGraph" />
            </div>
            <div className="results-container__widgets" key="rainInHour">
              <Widget data={result} type="hourPrecipitation" />
            </div>
            <div className="results-container__widgets" key="wind">
              <Widget data={result} type="wind" />
            </div>
            <div className="results-container__widgets" key="pressure">
              <Widget data={result} type="barometer" />
            </div>
            <div className="results-container__widgets" key="airQual">
              <Widget data={result} type="airQualSmall" />
            </div>
            <div className="results-container__widgets" key="sunRise">
              <Widget data={result} type="sunGraph" />
            </div>
            <div className="results-container__widgets" key="digitalHour">
              <Widget data={result} type="digitalHour" />
            </div>
          </ResponsiveReactGridLayout>
        </div>
        <span className="results-container__credits">Données par <a href="https://openweathermap.org/" rel="noreferrer" target="_blank">Openweather</a> et <a href="https://open-meteo.com/" rel="noreferrer" target="_blank">Open-Meteo</a></span>
      </div>
    </main>
  );
};

export default WeatherResults;
