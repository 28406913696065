import {FunctionComponent} from "react";
import "./sideWeatherInfo.css";
import SmallWeatherInfo from "./smallWeatherInfo";
import {weatherData} from "../../types/weather";

type Props = {
  weatherInfo?: weatherData;
  class?: string;
};

const SideWeatherInfo: FunctionComponent<Props> = (props: Props) => {

  const rows = [];

  for (let i = 0; i < 5; i++) {
    if (props.weatherInfo?.day7 !== undefined) {
      if (i === 0 && props.weatherInfo.actual.weather.temp !== undefined && props.weatherInfo.actual.weather.weatherType) {
        props.weatherInfo.day7[0].weather.tempMax = props.weatherInfo.actual.weather.temp;
        props.weatherInfo.day7[0].weather.weatherType = props.weatherInfo.actual.weather.weatherType;
      }
      rows.push(<SmallWeatherInfo key={i}
                                  class={(i < 4 ? "side-info__item" : "")}
                                  weatherInfos={props.weatherInfo.day7[i]}/>)
    }
  }

  return (
    <div className={`side-info ${props.class}`}>
      {rows}
    </div>
  )
}

export default SideWeatherInfo;
