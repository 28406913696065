import React, {
  CSSProperties,
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState
} from "react";
import {weatherData} from "../../types/weather";
import Widget from "../widgets/widget";
import "./modaleWidget.css";
import PrimaryButton from "../buttons/primaryButton";
import SecondaryButton from "../buttons/secondaryButton";
import {Layout} from "react-grid-layout";
import {widgetDim} from "./editableGrid";

type widget = {
  key: string;
  w: number;
  h: number
}

type modalProp = {
  data?: weatherData;
  addToLayout: (key: string, w: number, h: number) => void;
  display: boolean;
  hideModal: () => void;
  currentLayout: Layout[];
  maxDim: widgetDim;
}
const ModalWidget: FunctionComponent<modalProp> = ({
                                                     data,
                                                     addToLayout,
                                                     display,
                                                     hideModal,
                                                     currentLayout,
                                                     maxDim
                                                   }) => {

  const [selected, setSelected] = useState<widget | null>(null);

  const widgetList: widget[] = [
    {key: "hourPrecipitation", w: 1, h: 1},
    {key: "visibility", w: 1, h: 1},
    {key: "airQualSmall", w: 1, h: 1},
    {key: "wind", w: 1, h: 1},
    {key: "barometer", w: 1, h: 1},
    {key: "digitalHour", w: 1, h: 1},
    {key: "sunHours", w: 1, h: 1},
    {key: "humidity", w: 1, h: 1},
    {key: "dayTemp", w: 1, h: 1},
    {key: "tempGraph", w: 2, h: 1},
    {key: "sunGraph", w: 2, h: 1},
    {key: "weekForecast", w: 1, h: 2},
    {key: "airInfos", w: 2, h: 2},
  ]

  const getWidgetAreaStyle = (w: widget): CSSProperties | undefined => {
    let result: CSSProperties | undefined = undefined;
    if (w.w === 2)
      result = {gridColumn: "span 2"};
    if (w.h === 2)
      result = {...result, gridRow: "span 2"}
    return result;
  }

  const createElement = (widget: widget, key: number) => {
    if ((currentLayout.findIndex(el => el.i === widget.key) !== -1) || widget.w > maxDim.w
      || widget.h > maxDim.h || (widget.w * widget.h > maxDim.area)) {
      return (
        <div className="modal__grid-item unselected" key={`tmp${key}`}
             style={getWidgetAreaStyle(widget)}>
          <Widget type={widget.key} data={data}/>
        </div>
      )
    }
    return (
      <div style={getWidgetAreaStyle(widget)}
           className={`modal__grid-item ${(!selected || selected.key) === widget.key ? "selected" : ""}`}
           onClick={() => {
             setSelected(widget)
           }} key={`tmp${key}`}>
        <Widget type={widget.key} data={data}/>
      </div>
    )
  }

  const handleValidation = () => {
    if (!selected)
      return;
    addToLayout(selected.key, selected.w, selected.h);
    hideModal();
  };

  return (
    <div className={`modal__background ${display ? "" : "hide"}`}>
      <div className="modal__container">
        <h3 className="subtitle">
          <span>Choisir un indicateur</span>
          <span className="title__point"></span>
        </h3>
        <div className="modal__grid">
          {widgetList.map((el, i) => createElement(el, i))}
        </div>
        <div className="btn__container">
          <SecondaryButton content="Annuler" action={() => {
            hideModal();
          }}/>
          <PrimaryButton content="Valider" action={handleValidation}/>
        </div>
      </div>
    </div>
  )
}

export default ModalWidget;
