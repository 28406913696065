import React, {
  FunctionComponent, TouchEventHandler, useEffect, useRef,
  useState
} from "react";
import EditableGrid from "../components/editableGrid/editableGrid";
import {Layout} from "react-grid-layout";
import "./board.css";
import {weatherData} from "../types/weather";
import {Navigate, useLoaderData} from "react-router-dom";
import arrow from "../icons/arrow.svg";
import AddNewBoard from "../components/editableGrid/addNewBoard";
import jwtDecode from "jwt-decode";
import ProtectedRoute, {authToken} from "../helpers/isUserConnected";
import crossNav from "../icons/crossDotnav.svg";
import {checkBoardQueryType} from "../types/checkBoardQueryType";
import Loader from "../components/loader";
import {useDispatch} from "react-redux";
import {setUser} from "../redux/actions/userActions";

export type boardInfos = {
  cityName: string;
  coords: { long: string, lat: string };
  layout: Layout[]
}

export type boardQueryResut = {
  id: number,
  userId: number,
  pageInformations: boardInfos
}

type navDotsProps = {
  index: number;
  amount: number;
}

enum Direction {
  NEXT,
  PREV
}

const getUserId = (): number => {
  const token = localStorage.getItem('token');
  if (!token) {
    return -1;
  }
  const decoded: authToken = jwtDecode(token);
  return decoded.userId;
}

const parseBoardQuery = (boardQuery: boardQueryResut[]): boardInfos[] => {
  const result: boardInfos[] = [];
  if (boardQuery === null)
    return result;
  boardQuery.forEach(board => {
    result.push(board.pageInformations);
  })
  return result;
}

const NavDots: FunctionComponent<navDotsProps> = ({amount, index}) => {
  return (
    <div className="nav__dot-container">
      {
        [...Array(amount)].map((_el, i) => (
          <div key={`nav${i}`}
               className={`nav__dot ${index === i ? "active" : "inactive"}`}></div>
        ))
      }
      <img className="nav__dot" src={crossNav} alt="add page"/>
    </div>
  )
}

const Board: FunctionComponent = () => {
  const [boardQuery, setBoardQuery] = useState<boardQueryResut[]>([]);
  const [boardInfos, setBoardInfos] = useState<boardInfos[]>(parseBoardQuery(boardQuery));
  const [allData, setAllData] = useState<(weatherData | undefined)[]>(Array(boardInfos.length));
  const [showIndex, setShowIndex] = useState<number>(0);
  const [needLogin, setNeedLogin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let touchStart: number | null = null;
  let touchEnd: number | null = null;
  const minSwipeDistance = 50
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const onTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    touchEnd = null;
    touchStart = e.touches[0].clientX
  }
  const onTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
    touchEnd = e.touches[0].clientX
  }

  const onTouchEnd: TouchEventHandler<HTMLDivElement> = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      return changeBoardView(Direction.NEXT);
    }
    if (isRightSwipe) {
      return changeBoardView(Direction.PREV);
    }
  }

  const loadAllData = async () => {
    try {
      if (!token)
        return setNeedLogin(true);
      const decoded: authToken = jwtDecode(token);
      if (!decoded.userId)
        return setNeedLogin(true);
      const response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/board/get-board/${decoded.userId}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });
      if (!response.ok) {
        localStorage.clear();
        dispatch(setUser(null));
        return setNeedLogin(true);
      }
      const result: boardQueryResut[] = checkBoardQueryType(await response.json());
      setBoardInfos(parseBoardQuery(result));
      setBoardQuery(result);
      setIsLoading(false);
    } catch (e) {
      return setNeedLogin(true);
    }
  }

  async function getBoardData(infos: boardInfos): Promise<weatherData | undefined> {
    const response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/weather/city-data?lat=${infos.coords.lat}&lng=${infos.coords.long}`, {
      method: 'GET',
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      mode: "cors",
    });
    if (!response.ok) {
      return undefined;
    }
    return await response.json();
  }

  const handleData = async () => {
    let promises: Promise<weatherData | undefined>[] = [];
    const datas: (weatherData | undefined)[] = Array(boardInfos.length);
    boardInfos.forEach((infos) => {
      promises.push(getBoardData(infos));
    });
    const result = await Promise.all(promises);
    result.forEach((el, i) => {
      datas[i] = el;
    })
    setAllData(datas);
  }

  useEffect(() => {
    loadAllData()
  }, []);

  useEffect(() => {
    setBoardInfos(parseBoardQuery(boardQuery));
    handleData()
  }, [boardQuery]);

  const deleteBoard = (id: number) => {
    fetch(`${process.env.REACT_APP_SERVER_ADDR}/board/delete-board/${id}`, {
      method: 'DELETE',
      headers: {
        "content-type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${token}`
      },
      mode: "cors"
    });
    const index = boardQuery.findIndex((el) => el.id === id);
    if (index === -1)
      return;
    let tmpBoardQuery = Object.assign([], boardQuery);
    tmpBoardQuery.splice(index, 1);
    let tmp: weatherData[] = Object.assign([], allData);
    tmp.splice(index, 1);
    setBoardQuery(tmpBoardQuery);
    setShowIndex(showIndex - 1 < 0 ? 0 : showIndex - 1);
    setAllData(tmp);
  }

  const addBoard = async (cityName: string, long: string, lat: string) => {
    const toSend = {
      id: getUserId(),
      infos: JSON.stringify({
        cityName: cityName,
        coords: {lat: lat, long: long},
        layout: []
      })
    };
    const result = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/board/create-board/`, {
      method: 'POST',
      headers: {
        "content-type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${token}`
      },
      mode: "cors",
      body: JSON.stringify(toSend)
    });

    if (!result.ok)
      return;
    const data: boardQueryResut = await result.json();
    setBoardInfos([
      ...boardInfos,
      data.pageInformations
    ]);
    setBoardQuery([
      ...boardQuery,
      data
    ]);
    // console.log(data);
    setAllData([
      ...allData,
      await getBoardData(data.pageInformations)
    ])
  }

  const changeBoardView = (dir: Direction) => {
    switch (dir) {
      case Direction.NEXT:
        const newState = showIndex + 1 < boardQuery.length + 1 ? showIndex + 1 : boardQuery.length;
        setShowIndex(newState);
        break
      case Direction.PREV:
        setShowIndex(showIndex - 1 >= 0 ? showIndex - 1 : 0)
        break
      default:
        break
    }
  }

  if (needLogin)
    return <Navigate to={"/login"} state={{target: "/board"}} replace/>

  if (isLoading)
    return (
      <main className="loading__main">
        <Loader />
      </main>
    )

  return (
    <main className="board__main">
      <div className="board__container">
        <img className="board__arrow left" src={arrow} alt="previous board"
             onClick={() => changeBoardView(Direction.PREV)}
        />
        <div className="all__boards" onTouchStart={onTouchStart}
             onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
          <div style={{width: `${(boardQuery.length + 1) * 100}%`}}
               className="all__boards-wrapper">
            {boardQuery.map((infos, i) => (
              <EditableGrid
                style={{transform: `translateX(-${showIndex * 100}%)`}}
                key={`board${i}`} boardInfos={infos.pageInformations}
                data={allData[i]} boardId={boardQuery[i].id}
                deleteBoard={deleteBoard}/>
            ))}
            <AddNewBoard style={{transform: `translateX(-${showIndex * 100}%)`}}
                         userID={getUserId()} addBoard={addBoard}/>
          </div>
          <NavDots index={showIndex} amount={boardQuery.length}/>
        </div>
        <img className="board__arrow right" src={arrow} alt="next arrow"
             onClick={() => changeBoardView(Direction.NEXT)}/>
      </div>
      <span className="board__main--credits">Données par <a
        href="https://openweathermap.org/" target="_blank">Openweather</a> et <a
        href="https://open-meteo.com/" target="_blank">Open-Meteo</a></span>
    </main>
  );
}

export default Board;
