import { ChangeEvent, FunctionComponent, useEffect, useRef, useState, MouseEvent, FormEvent } from "react";
import Navbar from "../components/navbar";
import Title from "../components/text/title";
import "./updatePassword.css"
import Check from "../svg/check.svg";
import PasswordChecker from "../components/passwordChecker";
import Modale from "../components/modales/modale";
import { useNavigate } from "react-router-dom";

type Field = {
    value: string;
    isValid: boolean;
    error?: string;
};

type Form = {
    pass: Field;
    passConf: Field;
};

type ErrorResponse = {
    msg: string;
};

type ToSend = {
    pwd : string
}

const UpdatePassword: FunctionComponent = () => {

    const [form, setForm] = useState<Form>({
        pass: { value: "", isValid: false },
        passConf: { value: "", isValid: false },
    });

    const password = useRef<HTMLInputElement>(null);
    const passConf = useRef<HTMLInputElement>(null);
    const [validForm, setValidForm] = useState(false);
    const [err, setErr] = useState<ErrorResponse | null>(null);
    const [success, setSuccess] = useState<Boolean>(false);
    const navigate = useNavigate();
    const isValidForm = (): boolean => {
        for (const [key, value] of Object.entries(form)) {
            if (value.value === "")
                return false;
        }
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.exec(form.pass.value) === null)
            return false;
        if (form.passConf.value !== form.pass.value)
            return false;
        return true;
    }

    useEffect(() => {
        const div: HTMLDivElement | null = document.querySelector('#root');
        if (!div)
            return;
        div.style.height = "100%";
        return () => {
            div.style.height = "auto";
        }
    }, []);

    useEffect(() => {
        setValidForm(isValidForm());
    }, [form]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newField: Field = { value: "", isValid: false };
        newField.value = e.currentTarget.value;
        setForm({ ...form, ...{ [e.currentTarget.name]: newField } });
    };

    const toogleVisibility = (ev: MouseEvent) => {
        ev.preventDefault();
        if (!ev.currentTarget.parentNode || !password.current || !passConf.current) return;
        password.current.type = password.current.type === "password" ? "text" : "password";
        passConf.current.type = passConf.current.type === "password" ? "text" : "password";
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validForm)
            return;
        const queryParameters = new URLSearchParams(window.location.search)
        setErr(null);
        setSuccess(false)
        const send: ToSend = { pwd: form.pass.value };
        let response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/update-password/${queryParameters.get("token")}`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(send),
        });

        if (!response.ok) {
            setErr(await response.json());
            return;
        }
        setSuccess(true);
        setTimeout(() => {
            navigate("/login");
        }, 1000);
    }

    return (
        <>
            <Navbar />
            <main className="reset__main">
                {err !== null ? <Modale status="fail" message={err.msg} /> : null}
                {success !== false ? <Modale status="success" message="Votre mot de passe a bien été modifié" /> : null}
                <div className="background__container"></div>
                <div className="update__container">
                    <div className="update__title">
                        <Title title="Réinitialiser le mot de passe" />
                    </div>
                    <span className="update__new-password">Vous pouvez saisir un nouveau mot de passe pour votre compte.</span>
                    <form className="form update-password-form" onSubmit={handleSubmit}>
                        <div className="form__field">
                            <label htmlFor="pass">Mot de passe</label>
                            <div className="password__input-container">
                                <input name="pass" id="pass" className="password__input"
                                    type="password" placeholder="password" ref={password}
                                    onChange={handleChange} />
                                <svg className="password__toggle" width="16" height="11"
                                    viewBox="0 0 16 11" fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={toogleVisibility}>
                                    <path
                                        d="M10.5 5.5C10.5 6.16304 10.2366 6.79893 9.76777 7.26777C9.29893 7.73661 8.66304 8 8 8C7.33696 8 6.70107 7.73661 6.23223 7.26777C5.76339 6.79893 5.5 6.16304 5.5 5.5C5.5 4.83696 5.76339 4.20107 6.23223 3.73223C6.70107 3.26339 7.33696 3 8 3C8.66304 3 9.29893 3.26339 9.76777 3.73223C10.2366 4.20107 10.5 4.83696 10.5 5.5Z"
                                        fill="#022B3A"
                                    />
                                    <path
                                        d="M0 5.5C0 5.5 3 0 8 0C13 0 16 5.5 16 5.5C16 5.5 13 11 8 11C3 11 0 5.5 0 5.5ZM8 9C8.92826 9 9.8185 8.63125 10.4749 7.97487C11.1313 7.3185 11.5 6.42826 11.5 5.5C11.5 4.57174 11.1313 3.6815 10.4749 3.02513C9.8185 2.36875 8.92826 2 8 2C7.07174 2 6.1815 2.36875 5.52513 3.02513C4.86875 3.6815 4.5 4.57174 4.5 5.5C4.5 6.42826 4.86875 7.3185 5.52513 7.97487C6.1815 8.63125 7.07174 9 8 9Z"
                                        fill="#022B3A"
                                    />
                                </svg>
                            </div>
                            <PasswordChecker pass={form.pass.value} />
                        </div>
                        <div className="form__field">
                            <label htmlFor="passConf">Confirmer mot de passe</label>
                            <div className="password__input-container">
                                <input name="passConf" id="passConf" className="password__input"
                                    type="password" placeholder="password" ref={passConf}
                                    onChange={handleChange} />
                                <svg className="password__toggle" width="16" height="11"
                                    viewBox="0 0 16 11" fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={toogleVisibility}>
                                    <path
                                        d="M10.5 5.5C10.5 6.16304 10.2366 6.79893 9.76777 7.26777C9.29893 7.73661 8.66304 8 8 8C7.33696 8 6.70107 7.73661 6.23223 7.26777C5.76339 6.79893 5.5 6.16304 5.5 5.5C5.5 4.83696 5.76339 4.20107 6.23223 3.73223C6.70107 3.26339 7.33696 3 8 3C8.66304 3 9.29893 3.26339 9.76777 3.73223C10.2366 4.20107 10.5 4.83696 10.5 5.5Z"
                                        fill="#022B3A"
                                    />
                                    <path
                                        d="M0 5.5C0 5.5 3 0 8 0C13 0 16 5.5 16 5.5C16 5.5 13 11 8 11C3 11 0 5.5 0 5.5ZM8 9C8.92826 9 9.8185 8.63125 10.4749 7.97487C11.1313 7.3185 11.5 6.42826 11.5 5.5C11.5 4.57174 11.1313 3.6815 10.4749 3.02513C9.8185 2.36875 8.92826 2 8 2C7.07174 2 6.1815 2.36875 5.52513 3.02513C4.86875 3.6815 4.5 4.57174 4.5 5.5C4.5 6.42826 4.86875 7.3185 5.52513 7.97487C6.1815 8.63125 7.07174 9 8 9Z"
                                        fill="#022B3A"
                                    />
                                </svg>
                                <p
                                    className={`form__warning ${form.passConf.value !== "" && (form.pass.value !== form.passConf.value) ? "show" : ""}`}>Les
                                    mots de passe de correspondent pas</p>
                            </div>
                        </div>
                        <button
                            className={`primary-button form__submit update-password-btn ${validForm ? "" : "invalid"}`}>Changer de mot de passe
                        </button>
                    </form>
                </div>
            </main>
        </>
    )
}

export default UpdatePassword;
