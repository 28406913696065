import React, {FunctionComponent} from "react";
import "./secondaryButton.css";

type Props = {
  content: String;
  url?: string;
  action?: React.MouseEventHandler<HTMLAnchorElement>;
};

const SecondaryButton: FunctionComponent<Props> = (props) => {
  return (
    <a href={props.url} className="secondary-button" onClick={props.action}>
      <span className="secondary-button__content">{props.content}</span>
    </a>
  );
}

export default SecondaryButton;
