import jwtDecode from "jwt-decode";
import React, {FunctionComponent, useEffect, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";
import UserService from "../services/user.service";
import {store} from "../redux/store";
import {setUser} from "../redux/actions/userActions";
import Loader from "../components/loader";

export type authToken = {
  expired_at: string;
  userId: number;
};

type protectedRouteProps = {
  children: JSX.Element;
  target: string
}
export const getTokenUser = async (): Promise<number | null> => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log("No token");
      return null;
    }
    const decoded: authToken = jwtDecode(token);
    let currentDate = new Date();

    // console.log(decoded.expired_at);

    if ((Number(decoded.expired_at) * 1000 < currentDate.getTime()) || !decoded.expired_at) {
      // console.log("Token expired.");
      return null;
    }

    const userService = new UserService();
    const response = await userService.getUserById(token, decoded.userId);

    if (response === null) {
      store.dispatch(setUser(null))
      localStorage.clear();
      return null;
    }

    // console.log("Valid token");
    return decoded.userId;
  } catch (e) {
    console.log(e);
    return null;
  }
}

const ProtectedRoute: FunctionComponent<protectedRouteProps> = ({children, target}) => {
  const [userState, setUserState] = useState<number | null | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    setUserState(undefined);
    getTokenUser().then(user => {
      setUserState(user);
    })
  }, [location.pathname]);

  if (userState === undefined)
    return (
      <main className="loading__main">
        <Loader />
      </main>
    );
  return userState !== null ? children : <Navigate to={"/login"} state={{target}} replace/>;
}
export default ProtectedRoute;
