import React, {FunctionComponent, useEffect, useState} from "react";
import "./modale.css";

type Props = {
  message: string;
  status: string;
  duration?: number
};

const Modale: FunctionComponent<Props> = ({message, status, duration}) => {
  const [show, setShow] = useState<boolean>(true);
  const [hide, setHide] = useState<string>("");

  useEffect(() => {
    setTimeout(() => {
      hideModale();
    }, duration || 3000);
  }, [])

  const hideModale = () => {
    setHide('hide');
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  return (
    <>
      {show ? (
        <div className={"modale " + status + " " + hide}>
          <span className="modale__msg">{message}</span>
          <button className="modale__btn" onClick={hideModale}>
            <svg className="modale__btn--svg" width="8" height="8"
                 viewBox="0 0 8 8" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.36396 5.07136L7.19239 7.89979L7.89949 7.19268L5.07107 4.36426L7.89949 1.53583L7.19239 0.828724L4.36396 3.65715L1.53553 0.828724L0.828427 1.53583L3.65685 4.36426L0.828427 7.19268L1.53553 7.89979L4.36396 5.07136Z"
                fill=""
              />
            </svg>
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Modale;
