import React, {CSSProperties, FunctionComponent} from "react";
import "./loupeButton.css";
import loupeSvg from "../../svg/loupe.svg"

type Props = {
  url?: string;
  action?: React.MouseEventHandler<HTMLAnchorElement>;
  style?: CSSProperties
};


const LoupeButton: FunctionComponent<Props> = (props) => {
  return (
    <a href={props.url} className="loupe-button" style={props.style}
       onClick={props.action}>
      <img src={loupeSvg} className="loupe-button__image" alt="Search"></img>
    </a>
  );
}

export default LoupeButton;
