import React, {FunctionComponent} from "react";
import "../editableGrid/editableGrid.css";

type Props = {
  editable?: boolean;
  showModal?: () => void
};

const CompletionWidget: FunctionComponent<Props> = ({
                                                      editable = false,
                                                      showModal
                                                    }) => {

  return (
    <div className={`widget-main completion ${editable ? "editable" : ""}`}
         onClick={(e) => {
           e.preventDefault();
           if (!showModal || !editable)
             return;
           showModal()
         }}>
      <svg width="49" height="48" viewBox="0 0 49 48" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10.0919 38.8492C6.15362 34.911 3.94113 29.5695 3.94113 24C3.94113 18.4304 6.15362 13.089 10.0919 9.15075C14.0301 5.21249 19.3716 3 24.9411 3C30.5107 3 35.8521 5.21249 39.7904 9.15075C43.7286 13.089 45.9411 18.4304 45.9411 24C45.9411 29.5695 43.7286 34.911 39.7904 38.8492C35.8521 42.7875 30.5107 45 24.9411 45C19.3716 45 14.0301 42.7875 10.0919 38.8492ZM7.97057 40.9705C12.4714 45.4714 18.5759 48 24.9411 48C31.3063 48 37.4108 45.4714 41.9117 40.9705C46.4125 36.4697 48.9411 30.3652 48.9411 24C48.9411 17.6348 46.4125 11.5303 41.9117 7.02943C37.4108 2.52856 31.3063 0 24.9411 0C18.5759 0 12.4714 2.52856 7.97057 7.02943C3.46969 11.5303 0.941132 17.6348 0.941132 24C0.941132 30.3652 3.46969 36.4697 7.97057 40.9705ZM22.6939 13.2917C22.6939 12.1871 23.5894 11.2917 24.6939 11.2917H25.1939C26.2985 11.2917 27.1939 12.1871 27.1939 13.2917V21.7998L35.6858 21.7998C36.7904 21.7998 37.6858 22.6952 37.6858 23.7998V24.2998C37.6858 25.4044 36.7904 26.2998 35.6858 26.2998L27.1939 26.2998L27.1939 34.7917C27.1939 35.8963 26.2985 36.7917 25.1939 36.7917H24.6939C23.5894 36.7917 22.6939 35.8963 22.6939 34.7917L22.6939 26.2998H14.1858C13.0812 26.2998 12.1858 25.4044 12.1858 24.2998V23.7998C12.1858 22.6952 13.0812 21.7998 14.1858 21.7998H22.6939V13.2917Z"
              fill="#022B3A" fillOpacity="0.25"/>
      </svg>
    </div>
  )
}

export default CompletionWidget;
