import { FunctionComponent, useEffect } from "react";
import Navbar from "../components/navbar";
import Title from "../components/text/title";
import "./thanksRegister.css"
import Check from "../svg/check.svg";

const ThanksRegister: FunctionComponent = () => {
    useEffect(() => {
        const div: HTMLDivElement | null = document.querySelector('#root');
        if (!div)
            return;
        div.style.height = "100%";
        return () => {
            div.style.height = "auto";
        }
    }, []);

    return (
        <>
            <Navbar />
            <main className="reset__main">
                <div className="background__container"></div>
                <div className="register-confirm__container">
                    <div className="reset-confirm__title">
                        <Title title="Merci" />
                    </div>
                    <img src={Check} alt="A simple check" className="reset-confirm__check" />
                    <span className="reset-confirm__sent">Votre compte a bien été créé.</span>
                    <span className="reset-confirm__chek-mail">Vous allez recevoir un mail sous peu pour valider votre compte.</span>
                    <span className="reset-confirm__chek-mail">Surveillez votre boîte mail.</span>
                </div>
            </main>
        </>
    )
}

export default ThanksRegister;
