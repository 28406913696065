import React, {FunctionComponent} from "react";
import "./cityHeader.css";

type Props = {
  city: string | null;
  lat: string | null;
  lng: string | null;
};

const CityHeader: FunctionComponent<Props> = (props: Props) => {
  return (
    <div>
      <h2 className="city-name">{props.city === 'undefined' ? 'Localisation inconnue' : props.city}</h2>
      <span className="city-co">{`${props.lat}, ${props.lng}`}</span>
    </div>
  );
};

export default CityHeader;
