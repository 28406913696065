import React, {FunctionComponent, useEffect, useState} from "react";
import "./widget.css";
import "./dayTemp.css"
import {weatherData} from "../../types/weather";
import Icon from "../icons/weatherIcon";
import arrow from "../../svg/compass_arrow.svg";

type Props = {
  data?: weatherData;
};

const DayTempWidget: FunctionComponent<Props> = (props: Props) => {

  const [cursorPos, setCursorPos] = useState(50);
  const [tempMin, setTempMin] = useState(props.data?.today.weather.tempMin);
  const [tempMax, setTempMax] = useState(props.data?.today.weather.tempMax);

  useEffect(() => {
    if (!props.data)
      return;
    const delta = props.data.today.weather.tempMax - props.data.today.weather.tempMin;
    const cursorPos = (props.data.actual.weather.temp - props.data.today.weather.tempMin) / delta * 100;
    if (props.data.actual.weather.temp > props.data.today.weather.tempMax) {
      setCursorPos(100);
      setTempMax(props.data.actual.weather.temp)
    } else if (props.data.actual.weather.temp < props.data.today.weather.tempMin) {
      setCursorPos(0);
      setTempMin(props.data.actual.weather.temp)
    } else {
      setCursorPos(cursorPos);
    }
  }, [props.data]);

  return (
    <>
      <div className="widget-daytemp__header">
        <span
          className="widget-daytemp__header--min">{tempMin === undefined ? '' : `${tempMin.toFixed(0)}°`}</span>
        <div className="widget-daytemp__center">
          <div className="widget-daytemp__center--cursor"
               style={{left: `${cursorPos}%`}}></div>
        </div>
        <span
          className="widget-daytemp__header--max">{tempMax === undefined ? '' : `${tempMax.toFixed(0)}°`}</span>
      </div>
      <div className="widget-daytemp__body">
        <Icon class="widget-daytemp__body--icon" color={1}
              weatherCode={props.data?.actual.weather.weatherType}/>
        <span
          className="widget-daytemp__body--data">{props.data?.actual.weather.temp.toFixed(0)}°</span>
      </div>
      <div className="widget-daytemp__footer">
        <img src={arrow} className="widget-daytemp__footer--icon"
             style={{transform: `rotate(${(props.data?.actual.wind.orientation ? props.data?.actual.wind.orientation + 180 : 0)}deg)`}}/>
        <span
          className="widget-daytemp__footer--data">{props.data?.actual.wind.speed.toFixed(0)} km/h</span>
      </div>
    </>
  )
}

export default DayTempWidget;
