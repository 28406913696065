import React, {FunctionComponent, useEffect, useState} from "react";
import "./widget.css";
import "./sunGraph.css"
import {weatherData} from "../../types/weather";

type Props = {
  data?: weatherData;
};

const SunGraphWidget: FunctionComponent<Props> = (props: Props) => {

  const [linePosition, setLinePosition] = useState<number>(50);
  const [cursorPosition, setCursorPosition] = useState<number>(50);
  const [cursorcolor, setCursorColor] = useState<string>("#1F7A8C");

  useEffect(() => {

    if (!props.data) {
      return;
    }

    const sunRiseMins: number = Number(props.data.today.sun.sunrise.split("h")[0]) * 60 + Number(props.data.today.sun.sunrise.split("h")[1]);
    const sunSetMins: number = Number(props.data.today.sun.sunset.split("h")[0]) * 60 + Number(props.data.today.sun.sunset.split("h")[1]);
    const actualHour: number = Number(props.data.actual.hour.split("h")[0]) * 60 + Number(props.data.actual.hour.split("h")[1]);
    const dayDuration: number = sunSetMins - sunRiseMins;
    const dayLightRatio: number = (dayDuration / 1440) * 80;

    setLinePosition(Math.round(dayLightRatio) + 10)

    if (actualHour > sunRiseMins && actualHour < sunSetMins) {
      setCursorPosition(((actualHour - sunRiseMins) / dayDuration * 46) + 27); // Positionnement du curseur en fonction de l'heure actuelle si il fait jour
      setCursorColor("#1F7A8C");
    } else if (actualHour <= sunRiseMins) {
      setCursorPosition(actualHour / sunRiseMins * 27); // Positionnement du curseur en fonction de l'heure actuelle si le soleil ne s'est pas encore levé
      setCursorColor("#022B3A");
    } else {
      setCursorPosition(((actualHour - sunSetMins) / (1440 - sunSetMins) * 27) + 73); // Positionnement du curseur en fonction de l'heure actuelle si le soleil s'est déjà couché
      setCursorColor("#022B3A")
    }
  }, [props.data])

  if (!props.data) {
    return <></>
  }

  // console.log(props.data.actual.hour);

  return (
    <>
      <div className="widget-sungraph__header">
        <div className="widget-sungraph__header--container">
          <span className="widget-sungraph__header--container__title">Levé de soleil</span>
          <span
            className="widget-sungraph__header--container__data">{props.data?.today.sun.sunrise}</span>
        </div>
        <div className="widget-sungraph__header--container">
          <span className="widget-sungraph__header--container__title">Couché de soleil</span>
          <span
            className="widget-sungraph__header--container__data">{props.data?.today.sun.sunset}</span>
        </div>
      </div>
      <div className="widget-sungraph__data">
        <svg viewBox="0 0 302 60" fill="none" xmlns="http://www.w3.org/2000/svg"
             className="widget-sungraph__data--path__top">
          <path
            d="M0 58C99 58 105.595 1.5 152.5 1.5C204.342 1.49828 205.5 58 302 58"
            stroke="#022B3A" strokeWidth="2"/>
        </svg>
        <svg viewBox={`0 0 302 ${60 * (linePosition - 10) / 80}`} fill="none"
             xmlns="http://www.w3.org/2000/svg"
             className="widget-sungraph__data--path__bot">
          <path
            d="M0 58C99 58 105.595 1.5 152.5 1.5C204.342 1.49828 205.5 58 302 58"
            stroke="#1F7A8C" strokeWidth="2"/>
        </svg>
        <div className="widget-sungraph__data--line"
             style={{top: `${linePosition}%`}}></div>
        <div className="widget-sungraph__data--cursor" style={{
          offsetDistance: `${cursorPosition}%`,
          background: cursorcolor
        }}></div>
      </div>
    </>
  )
}

export default SunGraphWidget;
