import React, { FunctionComponent } from "react";
import "./widget.css";
import "./hourPrecipitation.css"
import { weatherData } from "../../types/weather";

type Props = {
  data?: weatherData;
};

const HourPrecipitationWidget: FunctionComponent<Props> = (props: Props) => {

  return (
    <>
      <span className="widget-title">Précipitations dans l'heure</span>
      <span className="widget-rain-data" >{props.data?.actual.rain.chancesNextHour} %</span>
      <div className="widget-footer"></div>
    </>
  )
}

export default HourPrecipitationWidget;
