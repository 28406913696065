import React, {FunctionComponent} from "react";
import "./title.css";

type Props = {
  title: string | null;
};

const Title: FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <h2 className="title__text">
        <span>{props.title}</span>
        <span className="title__point"></span>
      </h2>
    </>
  );
};

export default Title;
