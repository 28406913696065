import React, { FunctionComponent } from "react";
import "./widget.css";
import "./sunHours.css"
import { weatherData } from "../../types/weather";
import sunrise from "../../svg/sunrise.svg";
import sunset from "../../svg/sunset.svg";
type Props = {
  data?: weatherData;
};

const SunHoursWidget: FunctionComponent<Props> = (props: Props) => {

  return (
    <>
      <div className="widget-sun-hours">
        <div className="widget-sun-hours__container">
          <img className="widget-sun-hours__container--img" src={sunrise} />
          <span className="widget-sun-hours__container--data">{props.data?.today.sun.sunrise}</span>
        </div>
        <div className="widget-sun-hours__container">
          <span className="widget-sun-hours__container--data">{props.data?.today.sun.sunset}</span>
          <img className="widget-sun-hours__container--img" src={sunset} />
        </div>
      </div>
    </>
  )
}

export default SunHoursWidget;
