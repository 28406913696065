import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from "react";
import {Link, useNavigate} from "react-router-dom";
import Modale from "../components/modales/modale";
import Navbar from "../components/navbar";
import Title from "../components/text/title";
import "./login.css";
import Loader from "../components/loader";

type Field = {
  value: string;
  isValid: boolean;
  error?: string;
};

type Form = {
  user: Field;
  pass: Field;
};

type ToSend = {
  email: string;
  pwd: string;
};

type LogResponse = {
  token: string;
};

type ErrorResponse = {
  msg: string;
};

type LoginProperty = {
  target?: string
}

const Login: FunctionComponent<LoginProperty> = () => {
  const password = useRef<HTMLInputElement>(null);
  const [form, setForm] = useState<Form>({
    user: {value: "", isValid: false},
    pass: {value: "", isValid: false}
  });
  const [err, setErr] = useState<ErrorResponse | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const div: HTMLDivElement | null = document.querySelector('#root');
    if (!div)
      return;
    div.style.height = "100%";
    return () => {
      div.style.height = "auto";
    }
  }, []);

  const toogleVisibility = () => {
    if (!password.current) return;
    if (password.current.type === "password") password.current.type = "text";
    else password.current.type = "password";
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newField: Field = {value: "", isValid: false};
    newField.value = e.currentTarget.value;
    setForm({...form, ...{[e.currentTarget.name]: newField}});
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setLoading(true)
      setErr(null);
      const send: ToSend = {email: form.user.value, pwd: form.pass.value};
      let response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/login`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(send),
      });

      if (!response.ok) {
        setLoading(false)
        setErr(await response.json());
        return;
      }

      const token: LogResponse = await response.json();
      localStorage.setItem("token", token.token);
      const target = window.history.state?.usr?.target;
      navigate(target !== undefined ? target : "/");
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar/>
      <main className="main__login">
        {err !== null ? <Modale status="fail" message={err.msg} duration={7000}/> : null}
        <div className="background__container"></div>
        <div className="login__container">
          <Title title="Connexion"/>
          <div className="form__wrapper">
            {loading && <div className="loader__container-form"><Loader /></div>}
            <form className={`form ${loading ? "loading" : ""}`} onSubmit={handleSubmit} autoComplete="on">
              <div className="form__field">
                <label htmlFor="email">Nom d’utilisateur</label>
                <input name="user" className="input" id="email" type="text"
                       placeholder="john.doe@gmail.com" onChange={handleChange}
                       autoComplete="username"/>
              </div>
              <div className="form__field">
                <label htmlFor="pass">Mot de passe</label>
                <div className="password__input-container">
                  <input name="pass" id="pass" className="password__input"
                         type="password" placeholder="password" ref={password}
                         onChange={handleChange} autoComplete="current-password"/>
                  <svg className="password__toggle" width="16" height="11"
                       viewBox="0 0 16 11" fill="none"
                       xmlns="http://www.w3.org/2000/svg"
                       onClick={toogleVisibility}>
                    <path
                      d="M10.5 5.5C10.5 6.16304 10.2366 6.79893 9.76777 7.26777C9.29893 7.73661 8.66304 8 8 8C7.33696 8 6.70107 7.73661 6.23223 7.26777C5.76339 6.79893 5.5 6.16304 5.5 5.5C5.5 4.83696 5.76339 4.20107 6.23223 3.73223C6.70107 3.26339 7.33696 3 8 3C8.66304 3 9.29893 3.26339 9.76777 3.73223C10.2366 4.20107 10.5 4.83696 10.5 5.5Z"
                      fill="#022B3A"
                    />
                    <path
                      d="M0 5.5C0 5.5 3 0 8 0C13 0 16 5.5 16 5.5C16 5.5 13 11 8 11C3 11 0 5.5 0 5.5ZM8 9C8.92826 9 9.8185 8.63125 10.4749 7.97487C11.1313 7.3185 11.5 6.42826 11.5 5.5C11.5 4.57174 11.1313 3.6815 10.4749 3.02513C9.8185 2.36875 8.92826 2 8 2C7.07174 2 6.1815 2.36875 5.52513 3.02513C4.86875 3.6815 4.5 4.57174 4.5 5.5C4.5 6.42826 4.86875 7.3185 5.52513 7.97487C6.1815 8.63125 7.07174 9 8 9Z"
                      fill="#022B3A"
                    />
                  </svg>
                </div>
              </div>
              <button className="primary-button form__submit">Connexion</button>
            </form>
          </div>
          <div className="login__utils-container">
            <p>
              <Link className="link" to="/reset-password">
                Mot de passe oublié ?
              </Link>
            </p>
            <p>
              Pas encore de compte ?{" "}
              <Link to="/register" className="link">
                Inscription
              </Link>
            </p>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
