import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from "react";
import LoupeButton from "./buttons/loupeButton";
import PrimaryButton from "./buttons/primaryButton";
import "./citySearchBar.css";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;

type Props = {
  url: string;
};

type ButtonProps = {
  action?: React.MouseEventHandler<HTMLAnchorElement>;
  url: string;
};

type ResultsProps = {
  cities: Array<CityResult>;
  url: string;
};

type CityResult = {
  formatted: string;
  city: string;
  country: string;
  countryCode: string;
  postcode: string;
  key: number
  latitude: number;
  longitude: number;
}

const SearchButton: FunctionComponent<ButtonProps> = (props) => {

  const [width, setWidth] = useState<number>(window.innerWidth);

  const windowSizeHandler = (ev: UIEvent) => {
    setWidth(window.innerWidth);
  };

  window.addEventListener("resize", windowSizeHandler);

  return <>{width < 950 ? <LoupeButton action={props.action} url={props.url}/> :
    <PrimaryButton url={props.url} action={props.action} content="Rechercher"
                   style={{
                     margin: ".25rem 0",
                     padding: ".5rem 1rem ",
                     height: "2.25rem"
                   }}/>}</>;
}

const SearchResults: FunctionComponent<ResultsProps> = (props) => {

  const cities: Array<CityResult> = props.cities;

  return (
    <ul className="search-results">
      {cities.map(city => (
        <a className="search-results__li"
           href={`${props.url}?lat=${city.latitude}&lng=${city.longitude}&city=${city.city}`}
           key={city.key}>
          <li>{city.formatted}</li>
        </a>
      ))}
    </ul>
  );
}

const CitySearchBar: FunctionComponent<Props> = (props: Props) => {

  const [cityResults, setCityResults] = useState<Array<CityResult>>([]);
  const [inputValue, setInputValue] = useState<string>("");

  async function getCityInfos(cityName: string) {
    const response = await fetch(`${process.env.REACT_APP_SERVER_ADDR}/geocoding/get-city?cityName=${cityName}`, {
      method: 'GET',
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      mode: "cors",
    });
    let searchResult: Array<CityResult> = [];
    setCityResults([]);
    if (response.status !== 200) {
      return 0;
    }
    let cityList = (await (response.json())).results;
    if (!cityList || !cityList.length)
      return 0;
    for (let i = 0; i < 5 && i < cityList.length; i++) {
      let postcode = '';
      if (cityList[i].postcodes && cityList[i].postcodes.length > 0) {
        postcode = cityList[i].postcodes[0];
      }
      searchResult.push({
        formatted: cityList[i].name + " - " + postcode + " (" + cityList[i].country_code + ")",
        city: cityList[i].name,
        country: cityList[i].country,
        countryCode: cityList[i].country_code,
        postcode: postcode,
        key: i,
        latitude: cityList[i].latitude,
        longitude: cityList[i].longitude
      })
    }
    setCityResults(searchResult);
  }

  const handleChange = async (ev: FormEvent<HTMLInputElement>) => {
    ev.preventDefault();
    setInputValue(ev.currentTarget.value);
    if (inputValue.length < 3)
      return;
    await getCityInfos(inputValue);
  }

  useEffect(() => {
    if (inputValue.length === 0 && cityResults.length !== 0)
      setCityResults([]);
  }, [cityResults, inputValue]);

  async function getLocation() {
    navigator.geolocation.getCurrentPosition(async (position) => {
      console.log(position);
      const response = await fetch(`https://us1.locationiq.com/v1/reverse.php?key=pk.62be9fc462023a7697bf9ada5ee50694&lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`, {});
      if (response.status !== 200) {
        return 0;
      }
      const city = (await response.json()).address.city;
      if (window.top != null) {
        window.top.location.href = (`${props.url}?lat=${position.coords.latitude}&lng=${position.coords.longitude}&city=${city}`);
      }
    });
    return 0;
  }

  function handleEnterEvent(event: FormEvent): void {
    event.preventDefault();
    if (cityResults.length === 0 || cityResults[0] === undefined) {
      return;
    }
    if (window.top != null) {
      window.top.location.href = `${props.url}?lat=${cityResults[0].latitude}&lng=${cityResults[0].longitude}&city=${cityResults[0].city}`;
    }
    return;
  }

  return (
    <form className="city-searchbar" onSubmit={handleEnterEvent}>
      <div className="city-searchbar__main">
        <div className="city-searchbar__main--input">
          <input type='text'
                 className="city-searchbar__main--input__text"
                 placeholder="Rechercher une ville..."
                 value={inputValue}
                 onChange={handleChange}></input>
          <div className="city-searchbar__main--input__separator"></div>
          <div className="city-searchbar__main--input__geoloc"
               onClick={getLocation}>
            <img src="/img/location.svg" alt="location"
                 className="city-searchbar__main--input__geoloc--img"></img>
          </div>
        </div>
        {cityResults.length > 0 ?
          <SearchResults url={props.url} cities={cityResults}/> : null}
      </div>
      <SearchButton
        url={cityResults.length > 0 ? `${props.url}?lat=${cityResults[0].latitude}&lng=${cityResults[0].longitude}&city=${cityResults[0].city}` : ""}/>
    </form>
  )
}

export default CitySearchBar;
