import {createAction} from "@reduxjs/toolkit";
import {User} from "../../types/userType";

export const UserActionEnum = {
  SET_USER: "SET_USER",
  UNSET_USER: "UNSET_USER"
}

export const setUser = createAction<User | null>(UserActionEnum.SET_USER);
// export const unsetUser = createAction(UserActionEnum.UNSET_USER);
