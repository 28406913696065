import React, { FunctionComponent } from "react";
import "./compass.css"
import CompassImg from "../../../svg/compass.svg"
import CompassArrow from "../../../svg/compass_arrow.svg"

type Props = {
  orientation: number;
};

const CompassComponent : FunctionComponent<Props> = (props: Props) => {

  return (
    <>
      <img className="compass" src={CompassImg} alt="Compass" />
      <img style={{ transform: `translate(-50%, -50%) rotate(${props.orientation + 180}deg)` }} className="compass-arrow" src={CompassArrow} alt="Compass red arrow" />
    </>
  )
}

export default CompassComponent;
