import {FunctionComponent} from "react";
import {weather} from "../../types/weather";
import bigCloudBlue from "../../svg/icons/Type=BigCloud, Color=Blue.svg";
import bigCloudWhite from "../../svg/icons/Type=BigCloud, Color=White.svg";
import cloudBlue from "../../svg/icons/Type=Cloud, Color=Blue.svg";
import cloudWhite from "../../svg/icons/Type=Cloud, Color=White.svg";
import cloudMoonBlue from "../../svg/icons/Type=CloudMoon, Color=Blue.svg";
import cloudMoonWhite from "../../svg/icons/Type=CloudMoon, Color=White.svg";
import fogBlue from "../../svg/icons/Type=Fog, Color=Blue.svg";
import fogWhite from "../../svg/icons/Type=Fog, Color=White.svg";
import lightCloudBlue from "../../svg/icons/Type=LightCloud, Color=Blue.svg";
import lightCloudWhite from "../../svg/icons/Type=LightCloud, Color=White.svg";
import lightCloudMoonBlue
  from "../../svg/icons/Type=LightCloudMoon, Color=Blue.svg";
import lightCloudMoonWhite
  from "../../svg/icons/Type=LightCloudMoon, Color=White.svg";
import lightRainBlue from "../../svg/icons/Type=LightRain, Color=Blue.svg";
import lightRainWhite from "../../svg/icons/Type=LightRain, Color=White.svg";
import moonBlue from "../../svg/icons/Type=Moon, Color=Blue.svg";
import moonWhite from "../../svg/icons/Type=Moon, Color=White.svg";
import rainBlue from "../../svg/icons/Type=Rain, Color=Blue.svg";
import rainWhite from "../../svg/icons/Type=Rain, Color=White.svg";
import snowBlue from "../../svg/icons/Type=Snow, Color=Blue.svg";
import snowWhite from "../../svg/icons/Type=Snow, Color=White.svg";
import sunBlue from "../../svg/icons/Type=Sun, Color=Blue.svg";
import sunWhite from "../../svg/icons/Type=Sun, Color=White.svg";
import thunderBlue from "../../svg/icons/Type=Thunder, Color=Blue.svg";
import thunderWhite from "../../svg/icons/Type=Thunder, Color=White.svg";

type Props = {
  weatherCode?: weather;
  color: number;
  class: string;
};

const Icon: FunctionComponent<Props> = (props: Props) => {

  const iconMap = {
    0: [sunWhite, sunBlue],
    1: [lightCloudWhite, lightCloudBlue],
    2: [cloudWhite, cloudBlue],
    3: [bigCloudWhite, bigCloudBlue],
    4: [lightRainWhite, lightRainBlue],
    5: [rainWhite, rainBlue],
    6: [thunderWhite, thunderBlue],
    7: [fogWhite, fogBlue],
    8: [snowWhite, snowBlue],
    9: [moonWhite, moonBlue],
    10: [lightCloudMoonWhite, lightCloudMoonBlue],
    11: [cloudMoonWhite, cloudMoonBlue]
  }

  return (
    <img className={`${props.class}`}
         src={iconMap[(props.weatherCode !== undefined ? props.weatherCode : 0)][props.color]}
         alt="Icon weather"></img>
  )
}

export default Icon;
