import React, { FunctionComponent } from "react";
import "./widget.css";
import "./wind.css"
import { weatherData } from "../../types/weather";
import Compass from "./components/compass";


type Props = {
  data?: weatherData;
};

const WindWidget: FunctionComponent<Props> = (props: Props) => {

  if (!props.data) {
    return <></>;
  }
  return (
    <>
      <span className="widget-title">Vent</span>
        <Compass orientation={props.data.actual.wind.orientation} />
      <div><span className="widget-wind-data">{props.data?.actual.wind.speed ? Math.round(props.data?.actual.wind.speed) : "N/A"}</span><span className="widget-wind-unit"> km/h</span><span /></div>
    </>
  )
}

export default WindWidget;
